/*
@File: Edmy Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the template.

This files table contents are outlined below>>>>>

******************************************* 
** - DEFAULT AREA STYLE - **

** - Default Btn Area Style
** - Read More Area Style
** - Section Title Area Style
** - Header Area Style
** - Nav Area Style
** - Banner Area Style
** - Futures Area Style
** - About Area Style
** - Services Area Style
** - Ready To Talk Area Style
** - Choose Us Area Style
** - Case Studies Area Style
** - Customer Area Style
** - Team Area Style
** - Partner Area Style
** - Blog Area Style
** - Footer Area Style
** - Copy Right Area Style

** - OTHER STYLE AREA - **

** - Preloader Area Style
** - Go Top Style
** - Video wave Style

*******************************************
/*

/*
Default Style
============================*/

body {
	font-size: 16px;
	font-family: "Open Sans", sans-serif;
	color: #646464;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10+ and Edge */
	user-select: none; /* Standard syntax */
	touch-action: manipulation; /* Prevent double-tap to zoom */
}

body:has(.modal-backdrop) {
	overflow: hidden !important;
}

a {
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	text-decoration: none;
	color: #646464;
}

a * {
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	text-decoration: none;
	color: #646464;
	fill: #646464;
}

a:hover {
	text-decoration: none;
	/* color: #098b99; */
	color: #322e78;
}

a:hover * {
	text-decoration: none;
	/* color: #098b99; */
	color: #322e78;
	fill: #322e78;
}

a:focus {
	text-decoration: none;
}

button {
	outline: 0 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
	padding: 0;
}

button:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	/* color: #0d0c0c; */
	color: #322e78;
	font-weight: 700;
}

.d-table {
	width: 100%;
	height: 100%;
}

.d-table-cell {
	vertical-align: middle;
}

.form-group {
	margin-bottom: 20px;
}

p {
	font-size: 16px;
	margin-bottom: 15px;
	line-height: 1.8;
}

p:last-child {
	margin-bottom: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.form-control {
	height: 55px;
	/* color: #0d0c0c; */
	color: #1e2046;
	border: 1px solid #f5f4f4;
	/* background-color: #f2f0ef; */
	background-color: #f5f4f4;
	font-size: 16px;
	padding: 10px 20px;
	width: 100%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	border-radius: 5px;
}

.form-control::-webkit-input-placeholder {
	color: #757575;
}

.form-control:-ms-input-placeholder {
	color: #757575;
}

.form-control::-ms-input-placeholder {
	color: #757575;
}

.form-control::placeholder {
	color: #757575;
}

.form-control:focus {
	/* color: #000000; */
	color: #1e2046;
	background-color: #ffffff;
	background-color: #f5f4f4;
	-webkit-box-shadow: unset;
	box-shadow: unset;
	outline: 0;
	/* border: 1px solid #098b99; */
	border: 1px solid #322e78;
}

.form-control:hover:focus,
.form-control:focus {
	-webkit-box-shadow: unset;
	box-shadow: unset;
}

textarea.form-control {
	height: auto;
}

.ptb-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.pt-100 {
	padding-top: 100px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pt-70 {
	padding-top: 70px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pb-75 {
	padding-bottom: 75px;
}

.pb-150 {
	padding-bottom: 150px;
}

.pt-50 {
	padding-top: 50px;
}

.mt-30 {
	margin-top: 30px;
}

.mb-30 {
	margin-bottom: 30px;
}

.ml-15 {
	margin-left: 15px;
}

.mr-15 {
	margin-right: 15px;
}

/*
Bg-color Style*/
.bg-color-0f6c76 {
	background-color: #0f6c76;
}

.bg-color-f6fafb {
	/* background-color: #f6fafb; */
	background-color: #f5f4f4;
}

.bg-color-f1efee {
	background-color: #f1efee;
}

.bg-color-f2f0ef {
	background-color: #f2f0ef;
}

/*
Default Btn Area Style*/
.default-btn {
	font-size: 16px;
	color: #ffffff;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	text-align: center;
	position: relative;
	z-index: 1;
	overflow: hidden;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	padding: 16px 40px 12px;
	/* background: linear-gradient(133.65deg, #6cc17e 2.24%, #098b99 100%); */
	/* background: #5cb5dc; */
	background: linear-gradient(315deg, #1e2046 0%, #322e78 100%);
	border-radius: 3px;
	border-radius: 5px;
	font-weight: 600;
}

.default-btn::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: linear-gradient(315deg, #098b99 0%, #057fa8 100%); */
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	z-index: -1;
}

.default-btn i {
	position: relative;
	top: 2px;
	margin-left: 10px;
}

.default-btn.active {
	color: #ffffff;
}

.default-btn.active::before {
	opacity: 1;
}

.default-btn.active:hover {
	/* background-color: #098b99; */
	background-color: #f5f4f4;
	color: #1e2046;
}

.default-btn:hover {
	/* color: #ffffff; */
	background-color: #f5f4f4;
	/* color: #1e2046; */
}

.default-btn:hover::before {
	opacity: 1;
}

/*
Read More Btn Area Style*/
.read-more {
	font-size: 15px;
	color: #098b99;
	background-color: transparent;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	font-weight: 500;
}

.read-more i {
	position: relative;
	top: 4px;
}

.read-more:hover {
	color: #098b99;
}

.read-more.active {
	color: #098b99;
}

/*
Section Title Area Style*/
.section-title {
	max-width: 700px;
	margin: -5px auto 50px;
	text-align: center;
	position: relative;
}

.section-title .top-title {
	font-size: 25px;
	display: block;
	margin-bottom: 10px;
	font-weight: 600;
	text-transform: capitalize;
	/* background: linear-gradient(315deg, #098b99 0%, #057fa8 100%); */
	background-color: #922337;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.section-title h2 {
	font-size: 40px;
	margin-bottom: 15px;
	letter-spacing: -1px;
	line-height: 60px;
}

.section-title h2 span {
	color: #098b99;
}

.section-title h2:last-child {
	margin-bottom: 0;
}

.section-title p {
	max-width: 600px;
	margin: auto;
}

.section-title p span {
	color: #098b99;
}

.section-title.white-title span {
	color: #098b99;
}

.section-title.white-title h2 {
	color: #ffffff;
}

.section-title.white-title p {
	color: #ffffff;
}

.section-title.left-title {
	margin-left: 0;
	text-align: left;
	max-width: 645px;
}

.section-title.left-title h2 {
	max-width: 700px;
}

.section-title.left-title p {
	margin-left: 0;
}

.section-title.left-title .default-btn {
	position: absolute;
	top: 0;
	right: 0;
}

/*
Top Header Area Style
======================================================*/
.top-header-area {
	padding-top: 8px;
	padding-bottom: 8px;
}

.top-header-area .container-fluid {
	max-width: 1780px;
	margin: auto;
	padding-left: 30px;
	padding-right: 30px;
}

.header-left-content p {
	color: #ffffff;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: -1px;
	color: #e6f8fc;
}

.header-left-content p .read-more {
	color: #ffffff;
	font-weight: 700;
	font-size: 15px;
	letter-spacing: 0.05em;
}

.header-left-content p .read-more:hover {
	color: #ffffff;
}

.header-right-content {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	text-align: right;
}

.header-right-content li {
	margin-right: 25px;
	padding-right: 30px;
	border-right: 1px solid #8fc4ca;
	display: inline-block;
	font-size: 15px;
}

.header-right-content li:last-child {
	margin-right: 0;
	padding-right: 0;
	border: none;
}

.header-right-content li .form-select {
	background-color: transparent;
	color: #e4f5f7;
	border: none;
	/* background-image: url("/public/images/icon/down-arrow.svg"); */
	background-position: right 0px center;
	background-size: 13px 12px;
	max-width: 150px;
	cursor: pointer;
	letter-spacing: -0.05em;
	font-weight: 600;
	font-size: 15px;
	padding-left: 0;
}

.header-right-content li .form-select option {
	color: #0d0c0c;
	padding-left: 15px;
}

.header-right-content li .form-select:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
}

.header-right-content li a {
	color: #ffffff;
	letter-spacing: -0.05em;
	font-weight: 600;
	font-size: 15px;
}

.header-right-content li.auth-link i {
	position: relative;
	top: 3px;
}

/*
Navbar Area Style
======================================================*/
.navbar-area {
	position: relative;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding-top: 10px;
	padding-bottom: 10px;
}

.navbar-area.is-sticky {
	top: -1px;
	left: 0;
	width: 100%;
	z-index: 999;
	position: -webkit-sticky;
	position: sticky;
	-webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	background-color: #ffffff !important;
}

.navbar-area .container-fluid {
	max-width: 1780px;
	margin: auto;
	padding-left: 30px;
	padding-right: 30px;
}

.navbar-area .navbar-brand {
	margin-right: 30px;
}

.navbar-area .navbar-brand .main-logo {
	display: block;
}

.navbar-area .navbar-brand .white-logo {
	display: none;
}

.desktop-nav {
	position: relative;
	z-index: 2;
	padding-top: 0;
	padding-bottom: 0;
}

.desktop-nav .navbar {
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	z-index: 2;
	position: unset;
	padding-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
}

.desktop-nav .navbar ul {
	margin-bottom: 0;
	list-style-type: none;
}

.desktop-nav .navbar .navbar-nav .nav-item {
	position: relative;
	/* padding-bottom: 35px;
  padding-top: 35px;
  padding-right: 0;
  padding-left: 0; */
	padding: 16px 20px 12px;
	border-radius: 5px;
}

.desktop-nav .navbar .navbar-nav .nav-item:has(a.active) {
	background: linear-gradient(315deg, #1e2046 0%, #322e78 100%);
}

.desktop-nav .navbar .navbar-nav .nav-item:has(a.active) * {
	fill: #fff;
}

.desktop-nav .navbar .navbar-nav .nav-item a {
	font-size: 16px;
	/* color: #111111; */
	color: #1e2046;
	line-height: 1;
	position: relative;
	font-weight: 600;
	letter-spacing: -0.05em;
	padding-left: 0;
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	/* margin-left: 20px;
  margin-right: 20px; */
}

.desktop-nav .navbar .navbar-nav .nav-item a * {
	fill: #1e2046;
}

.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle {
	padding-right: 20px;
}

.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle::before {
	content: "\EA4E";
	position: absolute;
	top: 0;
	right: 0;
	font-family: remixicon !important;
	line-height: 1;
	font-size: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle::after {
	display: none;
}

.desktop-nav .navbar .navbar-nav .nav-item a:hover,
.desktop-nav .navbar .navbar-nav .nav-item a:focus,
.desktop-nav .navbar .navbar-nav .nav-item a.active {
	/* color: #098b99; */
	color: #fff;
}

.desktop-nav .navbar .navbar-nav .nav-item a i {
	font-size: 18px;
	line-height: 0;
	position: relative;
	top: 4px;
}

.desktop-nav .navbar .navbar-nav .nav-item:last-child a {
	margin-right: 0;
	margin-bottom: 6px !important;
}

.desktop-nav .navbar .navbar-nav .nav-item:first-child a {
	margin-left: 0;
	margin-bottom: 6px !important;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover a:not(.active),
.desktop-nav .navbar .navbar-nav .nav-item:focus a:not(.active),
.desktop-nav .navbar .navbar-nav .nav-item.active a:not(.active) {
	/* color: #098b99; */
	color: #1e2046;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background: #ffffff;
	position: absolute;
	visibility: hidden;
	border-radius: 5px;
	display: block;
	width: 250px;
	border: none;
	z-index: 99;
	opacity: 0;
	top: 80px;
	left: 0;
	padding: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
	padding: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
	color: #0d0c0c;
	padding: 15px;
	border-bottom: 1px dashed #ededed;
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	display: block;
	position: relative;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::after {
	display: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::before {
	content: "\EA4E";
	position: absolute;
	top: 14px;
	right: 13px;
	font-family: remixicon !important;
	line-height: 1;
	font-size: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
	/* color: #098b99; */
	color: #922337;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
	left: 250px;
	top: 0;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
	color: #0d0c0c;
	border-bottom: 1px dashed #ededed;
	font-size: 15px;
	font-weight: 600;
	line-height: 1;
	position: relative;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.dropdown-toggle::after {
	display: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.dropdown-toggle::before {
	content: "\EA4E";
	position: absolute;
	top: -2px;
	right: -2px;
	font-family: remixicon !important;
	line-height: 1;
	font-size: 23px;
	font-weight: 500;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
	/* color: #098b99; */
	color: #922337;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: 250px;
	top: 0;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #000000;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	/* color: #098b99; */
	color: #922337;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: -250px;
	top: 0;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #000000;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	/* color: #098b99; */
	color: #922337;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: -250px;
	top: 0;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #000000;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	/* color: #098b99; */
	color: #922337;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: -250px;
	top: 0;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #000000;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	/* color: #098b99; */
	color: #922337;
}

.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu {
	left: -250px;
	top: 0;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
}

.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a {
	color: #000000;
}

.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	/* color: #098b99; */
	color: #922337;
}

.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li.active
	a {
	color: #098b99;
}

.desktop-nav
	.navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li
	.dropdown-menu
	li:hover
	.dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
	color: #098b99;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -1px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
	color: #098b99;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	top: -1px;
	visibility: visible;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
	color: #098b99;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	top: -15px;
	visibility: visible;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
	color: #098b99;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
	color: #098b99;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child a {
	border-bottom: none;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
	top: 100%;
	opacity: 1;
	margin-top: 0;
	visibility: visible;
}

.desktop-nav .navbar .others-options {
	margin-left: 30px;
	position: relative;
	top: 0;
}

.desktop-nav .navbar .others-options ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.desktop-nav .navbar .others-options ul li {
	display: inline-block;
	margin-right: 30px;
}

.desktop-nav .navbar .others-options ul li:last-child {
	margin-right: 0;
}

.desktop-nav .navbar .others-options ul li .read-more {
	font-weight: 700;
	font-size: 16px;
}

.desktop-nav .navbar .others-options ul li .default-btn {
	display: block;
}

.desktop-nav .navbar .others-options ul li .default-btn i {
	display: none;
	font-size: 20px;
	line-height: 1;
}

.desktop-nav .navbar .others-options ul li:nth-child(1) {
	border-left: 1px solid #ececec;
	border-right: 1px solid #ececec;
	padding-left: 30px;
	padding-right: 30px;
}

.desktop-nav .navbar .others-options ul li .cart {
	font-size: 20px;
	position: relative;
	margin-right: 5px;
	top: 0;
	color: #0d0c0c;
}

.desktop-nav .navbar .others-options ul li .cart span {
	font-weight: 700;
	font-size: 12px;
	display: inline-block;
	width: 17px;
	height: 17px;
	line-height: 18px;
	background: linear-gradient(133.65deg, #6cc17e 2.24%, #098b99 100%);
	color: #ffffff;
	text-align: center;
	border-radius: 50%;
	position: absolute;
	top: -14px;
	right: -12px;
}

.desktop-nav .navbar .others-options .search-form {
	position: relative;
	margin-left: 0;
	background-color: #f1f1f1;
	width: 560px;
	height: 54px;
	border-radius: 6px;
}

.desktop-nav .navbar .others-options .search-form .form-control::-webkit-input-placeholder {
	color: #858585;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: -1px;
}

.desktop-nav .navbar .others-options .search-form .form-control:-ms-input-placeholder {
	color: #858585;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: -1px;
}

.desktop-nav .navbar .others-options .search-form .form-control::-ms-input-placeholder {
	color: #858585;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: -1px;
}

.desktop-nav .navbar .others-options .search-form .form-control::placeholder {
	color: #858585;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: -1px;
}

.desktop-nav .navbar .others-options .search-form .src-btn {
	position: absolute;
	top: 14px;
	right: 17px;
	background-color: transparent;
	font-size: 20px;
	line-height: 1;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	color: #757575;
}

.desktop-nav .navbar .others-options .search-form .src-btn:hover {
	color: #098b99;
}

.desktop-nav .navbar .others-options .search-form.style1 .src-btn i {
	color: #098b99;
	font-size: 25px;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
}

.dark-mode-btn {
	position: fixed;
	bottom: 60px;
	right: 40px;
	z-index: 1;
	width: 70px;
}

.dark-mode-btn .dark-version {
	text-align: center;
	float: right;
	z-index: 2;
	position: relative;
}

.dark-mode-btn .dark-version .switch {
	width: 49px;
	height: 49px;
	position: relative;
	border-radius: 50%;
	margin-bottom: -19px;
}

.dark-mode-btn .dark-version .switch input {
	width: 0;
	height: 0;
	opacity: 0;
	display: block;
}

.dark-mode-btn .dark-version .slider {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: pointer;
	position: absolute;
	color: #ffffff;
	background-color: #2e2e2e;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.dark-mode-btn .dark-version .slider:before {
	left: 0;
	top: 50%;
	right: 0;
	content: "\f1bf";
	position: absolute;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	font-size: 22px;
	font-family: remixicon !important;
}

.dark-mode-btn .dark-version .slider.round {
	border-radius: 50%;
}

.dark-mode-btn .dark-version .slider.round:before {
	border-radius: 50%;
}

.dark-mode-btn .dark-version input:checked + .slider {
	background-color: #efefef;
}

.dark-mode-btn .dark-version input:checked + .slider:before {
	color: #0d0c0c;
	content: "\ef72";
}

.navbar-toggler {
	border: none;
	background: #eee !important;
	padding: 10px;
	border-radius: 5px;
}

.navbar-toggler:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.navbar-toggler .icon-bar {
	width: 28px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	/* background: #098b99; */
	background: #1e2046;
	height: 2px;
	display: block;
}

.navbar-toggler .top-bar {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transform-origin: 10% 10%;
	transform-origin: 10% 10%;
	left: 4px;
	position: relative;
}

.navbar-toggler .middle-bar {
	opacity: 0;
	margin: 6px 0;
}

.navbar-toggler .bottom-bar {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transform-origin: 10% 90%;
	transform-origin: 10% 90%;
	left: 4px;
	position: relative;
}

.navbar-toggler.collapsed .top-bar {
	-webkit-transform: rotate(0);
	transform: rotate(0);
	left: 0;
}

.navbar-toggler.collapsed .middle-bar {
	opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
	-webkit-transform: rotate(0);
	transform: rotate(0);
	left: 0;
}

/*
Offcanvas Area
=====================================================*/
.offcanvas-area .offcanvas-header {
	background-color: #f9f9f9;
}

.offcanvas-area .offcanvas-header .btn-close {
	-webkit-box-shadow: none;
	box-shadow: none;
	opacity: 1;
}

.offcanvas-area .offcanvas-body {
	background-color: #ffffff;
	padding: 30px;
}

.offcanvas-area .offcanvas-body h3 {
	font-size: 20px;
	margin-bottom: 15px;
}

.offcanvas-area .offcanvas-body .offcanvas-about {
	margin-bottom: 30px;
}

.offcanvas-area .offcanvas-body .contact-info {
	margin-bottom: 30px;
}

.offcanvas-area .offcanvas-body .contact-info ul {
	padding-left: 0;
	list-style-type: none;
}

.offcanvas-area .offcanvas-body .contact-info ul li {
	margin-bottom: 20px;
}

.offcanvas-area .offcanvas-body .contact-info ul li:last-child {
	margin-bottom: 0;
}

.offcanvas-area .offcanvas-body .contact-info ul li span {
	display: block;
	color: #0d0c0c;
	margin-bottom: 5px;
	font-weight: 700;
}

.offcanvas-area .offcanvas-body .social-link {
	margin-bottom: 30px;
}

.offcanvas-area .offcanvas-body .social-link ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.offcanvas-area .offcanvas-body .social-link ul li {
	display: inline-block;
}

.offcanvas-area .offcanvas-body .social-link ul li a {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	background-color: #000000;
	color: #ffffff;
	display: inline-block;
	border-radius: 50px;
}

.offcanvas-area .offcanvas-body .social-link ul li a:hover {
	background-color: #098b99;
}

.offcanvas-area .offcanvas-body .map-wrap iframe {
	width: 100%;
	height: 300px;
	border-radius: 5px;
}

/*
Banner Area Style
======================================================*/
.banner-area {
	/* position: relative;
  z-index: 1;
  padding-top: 166px;
  padding-bottom: 225px; */
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
}

.banner-area .container-fluid {
	/* max-width: 1780px;
  padding-left: 30px;
  padding-right: 30px; */
}

.banner-area.bg-1 {
	background: linear-gradient(315deg, rgba(30, 32, 70, 0.85) 0%, rgba(39, 35, 96, 0.85) 30%, rgba(50, 46, 120, 0.85) 60%, rgba(61, 57, 136, 0.85) 100%),
		url("/public/images/building.jpeg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(100dvh - 77.61px);
}

.banner-area .shape {
	position: absolute;
	z-index: -1;
}

.banner-area .shape.shape-1 {
	top: 80px;
	left: 0;
	right: 0;
	margin: auto;
	-webkit-animation: off-on 5s infinite linear;
	animation: off-on 5s infinite linear;
}

.banner-area .shape.shape-2 {
	bottom: -80px;
	left: 200px;
	-webkit-animation: off-on 10s infinite linear;
	animation: off-on 10s infinite linear;
}

.banner-area .shape.shape-3 {
	bottom: 100px;
	right: 100px;
	-webkit-animation: off-on 15s infinite linear;
	animation: off-on 15s infinite linear;
}

.banner-img {
	position: relative;
	text-align: center;
	z-index: 1;
	/* margin-left: 50px; */
	margin: 0 auto;
}

.banner-img::before {
	content: "";
	position: absolute;
	top: -60px;
	width: 719px;
	height: 719px;
	/* background: linear-gradient(147.92deg, rgba(255, 255, 255, 0.4) 11.41%, rgba(255, 255, 255, 0) 90.25%); */
	border-radius: 50%;
	z-index: -1;
}

.pager-banner {
	position: relative;
	z-index: 1;
	margin: 0 auto;
}

.page-banner::before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 750px;
	height: 750px;
	background: linear-gradient(147.92deg, rgba(255, 255, 255, 0.4) 11.41%, rgba(255, 255, 255, 0) 90.25%);
	border-radius: 50%;
	z-index: -1;
}

@-webkit-keyframes off-on {
	0% {
		opacity: 0;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes off-on {
	0% {
		opacity: 0;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.banner-content {
	max-width: 690px;
}

.banner-content h1 {
	font-size: 70px;
	color: #ffffff;
	margin-bottom: 20px;
	letter-spacing: -3px;
	line-height: 71px;
}

.banner-content p {
	color: #ffffff;
	font-size: 18px;
	margin-bottom: 30px;
	font-weight: 600;
	color: #edfff4;
}

.banner-content p span {
	/* color: #fec400; */
	color: #5cb5dc;
}

.banner-content .search-form {
	position: relative;
	margin-bottom: 30px;
}

.banner-content .search-form .form-control {
	background: rgba(255, 255, 255, 0.3);
	-webkit-backdrop-filter: blur(60px);
	backdrop-filter: blur(60px);
	border-radius: 6px;
	backdrop-filter: blur(60px);
	border-color: transparent;
	color: #ffffff;
}

.banner-content .search-form .form-control:focus {
	border-color: #ffffff;
}

.banner-content .search-form .form-control::-webkit-input-placeholder {
	color: #d7f0f2;
	font-weight: 400;
	font-size: 16px;
}

.banner-content .search-form .form-control:-ms-input-placeholder {
	color: #d7f0f2;
	font-weight: 400;
	font-size: 16px;
}

.banner-content .search-form .form-control::-ms-input-placeholder {
	color: #d7f0f2;
	font-weight: 400;
	font-size: 16px;
}

.banner-content .search-form .form-control::placeholder {
	color: #d7f0f2;
	font-weight: 400;
	font-size: 16px;
}

.banner-content .search-form .default-btn {
	/* background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f0bf79),
    to(#f0bf79)
  );
  background-image: linear-gradient(#f0bf79, #f0bf79); */
	position: absolute;
	top: 0;
	right: 0;
	height: 55px;
	line-height: 56px;
	color: #111111;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 23px;
	padding-right: 23px;
}

.banner-content .search-form .default-btn::before {
	/* background: linear-gradient(312.38deg, #6cc17e 2.65%, #098b99 98.23%); */
}

.banner-content .search-form .default-btn i {
	position: relative;
	top: -1px;
}

.banner-content .search-form .default-btn img {
	position: relative;
	top: 16px;
	margin-left: 10px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.banner-content .search-form .default-btn:hover {
	/* color: #ffffff; */
	background-color: #f5f4f4;
	color: #1e2046;
}

.banner-content .search-form .default-btn:hover img {
	-webkit-filter: brightness(500);
	filter: brightness(500);
}

.banner-content .client-list {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.banner-content .client-list li {
	display: inline-block;
}

.banner-content .client-list li img {
	border-radius: 50px;
	width: 46px;
	height: 46px;
}

.banner-content .client-list li .client {
	margin-left: -20px;
}

.banner-content .client-list li p {
	position: relative;
	top: 2px;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 18px;
	font-weight: 700;
}

.banner-content .client-list li p a {
	color: #fec400;
	font-size: 18px;
	font-weight: 700;
	position: relative;
	z-index: 1;
}

.banner-content .client-list li p a::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 87%;
	height: 1px;
	background-color: #fec400;
	z-index: -1;
}

/*
Courses Area Style
======================================================*/
.feature-dcourses-area {
	position: relative;
}

.feature-dcourses-area .courses-shape {
	position: absolute;
	top: -50px;
	right: 150px;
}

.feature-dcourses-area .default-btn {
	-webkit-box-shadow: 0px 4px 25px rgba(9, 139, 153, 0.3);
	box-shadow: 0px 4px 25px rgba(9, 139, 153, 0.3);
}

.most-viewed-courses-area .default-btn {
	-webkit-box-shadow: 0px 4px 25px rgba(9, 139, 153, 0.3);
	box-shadow: 0px 4px 25px rgba(9, 139, 153, 0.3);
}

.custom-col {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 20%;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.single-courses {
	border: #0000002e solid 1px;
	border-radius: 10px;
	margin-bottom: 30px;
	position: relative;
	z-index: 1;
}

.single-courses .courses-main-img {
	margin-bottom: 15px;
	display: block;
	padding: 40px;
	text-align: center;
}

.single-courses .courses-main-img img {
	border-radius: 5px;
}

.single-courses .courses-content {
	text-align: center;
}

.single-courses .courses-content h3 {
	font-size: 16px;
	margin-bottom: 10px;
	line-height: 30px;
	font-weight: 600;
}

.single-courses .courses-content h3 a {
	color: #0d0c0c;
}

.single-courses .courses-content ul {
	padding-left: 0;
	margin-bottom: 10px;
	list-style-type: none;
}

.single-courses .courses-content ul.admin {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.single-courses .courses-content ul.admin li {
	display: inline-block;
	margin-right: 10px;
	color: #0d0c0c;
}

.single-courses .courses-content ul.admin li:last-child {
	margin-right: 0;
}

.single-courses .courses-content ul.admin li img {
	border-radius: 50%;
	position: relative;
	top: -2px;
	width: 25px;
	height: 25px;
}

.single-courses .courses-content ul.admin li span {
	color: #464646;
	font-size: 14px;
	font-weight: 400;
}

.single-courses .courses-content ul.admin li a {
	color: #0d0c0c;
	font-size: 14px;
	font-weight: 600;
}

.single-courses .courses-content ul.admin li a:hover {
	color: #098b99;
}

.single-courses .courses-content ul.rating {
	padding-left: 0;
	margin-bottom: 10px;
	list-style-type: none;
}

.single-courses .courses-content ul.rating li {
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
}

.single-courses .courses-content ul.rating li span {
	color: #fec400;
	font-weight: 700;
	font-size: 16px;
}

.single-courses .courses-content ul.rating li img {
	position: relative;
	top: -2px;
}

.single-courses .courses-content ul.rating li i {
	color: #fec400;
	position: relative;
	top: 2px;
}

.single-courses .courses-content h4 {
	font-size: 25px;
	letter-spacing: -1px;
	background: linear-gradient(315deg, #098b99 0%, #057fa8 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	margin-bottom: 0;
}

.single-courses .courses-content h4 del {
	font-weight: 400;
	font-size: 18px;
	background: linear-gradient(315deg, #939393 0%, #939393 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	position: relative;
}

.single-courses .courses-content h4 del::before {
	content: "";
	position: absolute;
	top: 8px;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: #939393;
}

.single-courses .courses-hover-content {
	/* background: linear-gradient(134.32deg, #6cc17e -19.8%, #098b99 100%); */
	/* background: linear-gradient(315deg, #1e2046 0%, #322e78 100%); */
	background: linear-gradient(315deg, rgba(30, 32, 70, 0.8) 0%, rgba(50, 46, 120, 0.8) 100%);
	border-radius: 5px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	visibility: hidden;
}

.single-courses .courses-hover-content .sk {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 30px 19px;
}

.single-courses .courses-hover-content h3 {
	font-weight: 700;
	font-size: 16px;
	line-height: 23px;
	margin-bottom: 12px;
}

.single-courses .courses-hover-content h3 a {
	color: #ffffff;
	/* white-space: nowrap; */
	font-size: 15px;
}

.single-courses .courses-hover-content p {
	color: #ffffff;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 20px;
	color: #dcf6f9;
}

.single-courses .courses-hover-content ul {
	padding-left: 0;
	margin-bottom: 20px;
	list-style-type: none;
}

.single-courses .courses-hover-content ul li {
	display: inline-block;
	color: #dcf6f9;
	position: relative;
	padding-left: 20px;
	margin-bottom: 10px;
	font-weight: 400;
	font-size: 14px;
}

.single-courses .courses-hover-content ul li:last-child {
	margin-bottom: 0;
}

.single-courses .courses-hover-content ul li i {
	color: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
}

.single-courses .courses-hover-content .courses-btn .default-btn {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#f0bf79), to(#f0bf79));
	background-image: linear-gradient(#f0bf79, #f0bf79);
	color: #0d0c0c;
	padding: 10px 28px 10px;
}

.single-courses .courses-hover-content .courses-btn .default-btn:hover {
	color: #ffffff;
}

.single-courses .courses-hover-content .courses-btn .default-btn.wish {
	padding: 3px 11px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#0f6c76), to(#0f6c76));
	background-image: linear-gradient(#0f6c76, #0f6c76);
}

.single-courses .courses-hover-content .courses-btn .default-btn.wish i {
	margin-left: 0;
	color: #ffffff;
	font-size: 25px;
	font-weight: 400;
	top: 0;
}

.single-courses .courses-hover-content .courses-btn .default-btn.wish .hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-courses .courses-hover-content .courses-btn .default-btn.wish:hover .hover {
	opacity: 1;
	color: red;
}

.single-courses:hover .courses-hover-content {
	opacity: 1;
	visibility: visible;
}

.feature-courses-slide.owl-theme .owl-nav {
	margin-top: 0;
}

.feature-courses-slide.owl-theme .owl-nav .owl-prev,
.feature-courses-slide.owl-theme .owl-nav .owl-next {
	position: absolute;
	top: 50%;
	left: -100px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	background: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	color: #098b99;
	font-size: 30px;
	border-radius: 50%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	overflow: hidden;
	-webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
}

.feature-courses-slide.owl-theme .owl-nav .owl-prev::before,
.feature-courses-slide.owl-theme .owl-nav .owl-next::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background: linear-gradient(315deg, #098b99 0%, #057fa8 100%);
	border-radius: 50%;
	z-index: -1;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.feature-courses-slide.owl-theme .owl-nav .owl-prev:hover,
.feature-courses-slide.owl-theme .owl-nav .owl-next:hover {
	color: #ffffff;
}

.feature-courses-slide.owl-theme .owl-nav .owl-prev:hover::before,
.feature-courses-slide.owl-theme .owl-nav .owl-next:hover::before {
	right: auto;
	left: 0;
	width: 100%;
}

.feature-courses-slide.owl-theme .owl-nav .owl-next {
	left: auto;
	right: -100px;
}

.feature-courses-slide.owl-theme .owl-nav .owl-next i {
	position: relative;
	right: -1px;
}

.feature-courses-slide.owl-carousel .owl-item img {
	display: unset;
	width: auto;
}

.viewed-courses-slide.owl-theme .owl-nav {
	margin-top: 0;
}

.viewed-courses-slide.owl-theme .owl-nav .owl-prev,
.viewed-courses-slide.owl-theme .owl-nav .owl-next {
	position: absolute;
	top: 50%;
	left: -100px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	background: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	color: #098b99;
	font-size: 30px;
	border-radius: 50%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	overflow: hidden;
	-webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
}

.viewed-courses-slide.owl-theme .owl-nav .owl-prev::before,
.viewed-courses-slide.owl-theme .owl-nav .owl-next::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background: linear-gradient(315deg, #098b99 0%, #057fa8 100%);
	border-radius: 50%;
	z-index: -1;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.viewed-courses-slide.owl-theme .owl-nav .owl-prev:hover,
.viewed-courses-slide.owl-theme .owl-nav .owl-next:hover {
	color: #ffffff;
}

.viewed-courses-slide.owl-theme .owl-nav .owl-prev:hover::before,
.viewed-courses-slide.owl-theme .owl-nav .owl-next:hover::before {
	right: auto;
	left: 0;
	width: 100%;
}

.viewed-courses-slide.owl-theme .owl-nav .owl-next {
	left: auto;
	right: -100px;
}

.viewed-courses-slide.owl-theme .owl-nav .owl-next i {
	position: relative;
	right: -1px;
}

.viewed-courses-slide.owl-carousel .owl-item img {
	display: unset;
	width: auto;
}

.search-result {
	margin-bottom: 30px;
}

.search-result p {
	font-size: 18px;
	color: #922337;
}

.search-result p span {
	/* color: #0d0c0c; */
	color: #322e78;
	font-weight: 600px;
}

.search-result ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
	text-align: right;
}

.search-result ul li {
	display: inline-block;
	margin-right: 10px;
}

.search-result ul li:last-child {
	margin-right: 0;
}

.search-result ul li .src-form {
	position: relative;
}

.search-result ul li .src-form .src-btn {
	position: absolute;
	top: 12px;
	right: 15px;
	font-size: 20px;
	background-color: transparent;
	color: #098b99;
	border-radius: 6px;
}

.search-result ul li .form-select {
	cursor: pointer;
	padding-right: 35px;
	/* background-image: url("/public/images/icon/down-arrow1.svg"); */
}

/*
Categorie Area Style
======================================================*/
.single-categorie {
	background: #ffffff;
	border: 1px solid #f1f1f1;
	border-radius: 5px;
	padding: 20px 30px 15px 30px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	margin-bottom: 30px;
	position: relative;
	z-index: 1;
}

.single-categorie::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	z-index: -1;
	background: linear-gradient(315deg, #098b99 0%, #057fa8 100%);
	border-radius: 5px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-categorie h3 {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-categorie i {
	color: #098b99;
	font-size: 20px;
	line-height: 1;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-categorie:hover {
	border-color: #098b99;
}

.single-categorie:hover::before {
	right: auto;
	left: 0;
	width: 100%;
}

.single-categorie:hover h3 {
	color: #ffffff;
}

.single-categorie:hover i {
	color: #ffffff;
}

.all-browse {
	font-size: 18px;
}

.all-browse .read-more {
	position: relative;
	font-weight: 600;
	font-size: 18px;
}

.all-browse .read-more::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 86%;
	height: 1px;
	background-color: #098b99;
}

/*
Transform Area Style
======================================================*/
.transform-area {
	overflow: hidden;
}

.transform-conetnt {
	margin-right: 30px;
}

.transform-conetnt h2 {
	font-size: 40px;
	margin-bottom: 15px;
	line-height: 60px;
	letter-spacing: -1px;
}

.transform-conetnt h2 span {
	color: #098b99;
}

.transform-conetnt p {
	margin-bottom: 30px;
}

.single-transform {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 30px;
}

.single-transform .transform-video-img {
	position: relative;
}

.single-transform .transform-video-img img {
	border-radius: 5px;
}

.single-transform .transform-video-img .video-btns {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.single-transform .transform-video-img .video-btns i {
	font-size: 40px;
	color: #ffffff;
	line-height: 1;
	border-radius: 50%;
	position: relative;
	z-index: 1;
}

.single-transform .transform-video-img .video-btns i::after {
	content: "";
	position: absolute;
	top: 6px;
	left: 6px;
	width: 30px;
	height: 30px;
	background-color: #098b99;
	border-radius: 50%;
	z-index: -1;
}

.single-transform .transform-video-content {
	margin-left: 20px;
}

.single-transform .transform-video-content h3 {
	font-size: 18px;
}

.single-transform .transform-video-content h3 a {
	color: #0d0c0c;
}

.single-transform .transform-video-content ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.single-transform .transform-video-content ul li {
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
}

.single-transform .transform-video-content ul li a.active {
	/* color: #098b99; */
	color: #922337;
}

.single-transform:hover h3 a {
	/* color: #098b99; */
	color: #922337;
}

.transform-img {
	text-align: center;
	margin-left: 30px;
	position: relative;
	z-index: 1;
}

.transform-img::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 515px;
	height: 515px;
	background: linear-gradient(134.98deg, rgba(155, 212, 167, 0.4) 14.29%, rgba(48, 158, 169, 0.1) 100%);
	border-radius: 50%;
	z-index: -1;
	margin: auto;
}

/*
Features Area Style
======================================================*/
.our-features-area {
	position: relative;
	z-index: 1;
}

.our-features-area .shape {
	position: absolute;
	z-index: -1;
}

.our-features-area .shape.shape-1 {
	top: 70px;
	left: 200px;
	z-index: -1;
	-webkit-animation: off-on 5s infinite linear;
	animation: off-on 5s infinite linear;
}

.single-features {
	background-color: #ffffff;
	padding: 38px;
	border-radius: 20px;
	text-align: center;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	margin-bottom: 30px;
}

.single-features img {
	margin-bottom: 33px;
}

.single-features h3 {
	font-size: 22px;
	margin-bottom: 20px;
}

.single-features .features-link {
	position: relative;
}

.single-features .features-link p {
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	margin-bottom: 0;
}

.single-features .features-link .features-btn {
	display: inline-block;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	background: linear-gradient(315deg, #098b99 0%, #057fa8 100%);
	-webkit-box-shadow: 0px 2px 20px rgba(0, 113, 220, 0.1);
	box-shadow: 0px 2px 20px rgba(0, 113, 220, 0.1);
	color: #ffffff;
	font-size: 25px;
	border-radius: 50%;
	position: absolute;
	bottom: 16px;
	left: 0;
	right: 0;
	margin: auto;
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-features:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

.single-features:hover .features-link p {
	opacity: 0;
}

.single-features:hover .features-link .features-btn {
	opacity: 1;
}

/*
Testimonial Area Style
======================================================*/
.testimonial-area {
	overflow: hidden;
}

.testimonials-conetent {
	position: relative;
	z-index: 1;
}

.testimonials-conetent h2 {
	font-size: 40px;
	margin-bottom: 20px;
	margin-left: 30px;
	line-height: 60px;
	letter-spacing: -1px;
}

.testimonials-conetent .testimonial-shape {
	position: absolute;
	bottom: 105px;
	right: 0;
	z-index: -1;
}

.single-testimonial {
	/* margin-bottom: 30px; */
}

.single-testimonial .testimonial-conetent {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
	border-radius: 30px 30px 30px 0px;
	padding: 30px;
	margin-bottom: 20px;
}

.single-testimonial .testimonial-conetent h3 {
	font-size: 20px;
	margin-bottom: 15px;
	color: #098b99;
}

.single-testimonial .testimonial-info img {
	width: 55px;
	height: 55px;
}

.single-testimonial .testimonial-info h4 {
	font-size: 16px;
	font-weight: 500;
}

.single-testimonial .testimonial-info h4 span {
	color: #646464;
}

.single-testimonial.m-30 {
	margin: 30px;
}

.single-testimonial.mr-100 {
	margin-right: 100px;
}

.testimonial-img {
	/* margin-bottom: 30px;
	margin-right: 100px; */
}

.testimonials-slide .swiper-pagination {
	position: initial;
}

.testimonials-slide .swiper-pagination .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
}

.testimonials-slide .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: #098b99;
}

.testimonials-slide-list-one.owl-carousel .owl-item img {
	width: auto;
}

.testimonials-slide-list-one .single-testimonial .testimonial-conetent h3 {
	color: #098b99;
	font-size: 20px;
}

.testimonials-slide-list-two.owl-carousel .owl-item img {
	width: auto;
}

.testimonials-slide-list-two.owl-carousel.owl-rtl {
	direction: rtl;
	text-align: left;
}

.testimonials-slide-list-two .testimonial-info {
	position: relative;
	display: block !important;
	padding-left: 70px;
}

.testimonials-slide-list-two .testimonial-info img {
	position: absolute;
	top: 0;
	left: 0;
}

.testimonials-slide-list-two .testimonial-info h4 {
	position: relative;
	top: 18px;
}

.testimonials-slide-list-two .single-testimonial .testimonial-conetent h3 {
	color: #098b99;
	font-size: 20px;
}

/*
Partner Area Style
======================================================*/
.partner-slide.owl-carousel .owl-item img {
	margin: auto;
	width: auto;
}

/*
Teaching Area Style
======================================================*/
.teaching-area {
	overflow: auto;
}

.teaching-content {
	margin-right: 80px;
}

.teaching-content h2 {
	font-size: 40px;
	margin-bottom: 20px;
	line-height: 60px;
	letter-spacing: -1px;
}

.teaching-content p {
	margin-bottom: 30px;
}

.teaching-content ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.teaching-content ul li {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.03);
	box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.03);
	border-radius: 6px;
	padding: 15px;
	margin-bottom: 24px;
	cursor: pointer;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.teaching-content ul li:last-child {
	margin-bottom: 0;
}

.teaching-content ul li h3 {
	font-size: 16px;
	margin-bottom: 0;
	margin-left: 15px;
}

.teaching-content ul li:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

.teaching-content .default-btn {
	margin-top: 30px;
}

.teaching-img {
	position: relative;
	z-index: 1;
	text-align: center;
}

.teaching-img::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 450px;
	height: 450px;
	/* background: linear-gradient(315deg, rgba(30, 32, 70, 0.85) 0%, rgba(39, 35, 96, 0.85) 30%, rgba(50, 46, 120, 0.85) 60%, rgba(61, 57, 136, 0.85) 100%); */
	border-radius: 50%;
	z-index: -1;
	margin: auto;
}

.teaching-img .teaching-img-shape {
	margin-bottom: 20px;
	-webkit-animation: off-on 10s infinite linear;
	animation: off-on 10s infinite linear;
}

/*
Business Area Style
======================================================*/
.business-area {
	overflow: hidden;
}

.business-bg {
	padding: 50px 30px;
}

.business-img {
	position: relative;
	z-index: 1;
	text-align: center;
}

.business-img::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 400px;
	height: 400px;
	background-color: #ffffff;
	border-radius: 50%;
	z-index: -1;
	margin: auto;
}

.business-content h2 {
	font-size: 40px;
	margin-bottom: 20px;
	letter-spacing: -1px;
	line-height: 60px;
}

.business-content p {
	margin-bottom: 30px;
}

.business-content.ml-50 {
	margin-left: 50px;
}

/*
Blog Area Style
======================================================*/
.blog-post-area .single-blog .blog-content h3 {
	font-size: 20px;
	font-weight: 600;
}

.single-blog {
	margin-bottom: 30px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-blog .blog-img {
	margin-bottom: 20px;
	position: relative;
}

.single-blog .blog-img img {
	border-radius: 5px;
}

.single-blog .blog-img .date {
	position: absolute;
	top: 20px;
	left: 20px;
	background-color: #fec400;
	color: #0d0c0c;
	width: 65px;
	height: 65px;
	line-height: 20px;
	text-align: center;
	background-color: #fec400;
	font-weight: 700;
	border-radius: 10px 10px 0px 10px;
	padding: 13px 11px;
}

.single-blog .blog-content h3 {
	font-size: 22px;
	border-bottom: 1px solid #f7f7f7;
	padding-bottom: 20px;
	margin-bottom: 20px;
	line-height: 32px;
}

.single-blog .blog-content h3 a {
	color: #0d0c0c;
}

.single-blog .blog-content ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.single-blog .blog-content ul li {
	display: inline-block;
}

.single-blog .blog-content ul li img {
	position: relative;
	top: -2px;
}

.single-blog .blog-content ul li.admin a {
	color: #0d0c0c;
	font-size: 16px;
	font-weight: 600;
}

.single-blog .blog-content ul li .read-more {
	font-weight: 600;
	font-size: 16px;
}

.single-blog:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

.single-blog:hover .blog-content h3 a {
	color: #098b99;
}

/*
Footer Area Style
======================================================*/
.footer-area {
	position: relative;
	z-index: 1;
}

.footer-area .shape {
	position: absolute;
	z-index: -1;
}

.footer-area .shape.shape-1 {
	top: -50px;
	right: 200px;
	-webkit-animation: off-on 10s infinite linear;
	animation: off-on 10s infinite linear;
}

.footer-area .shape.shape-2 {
	bottom: -35px;
	left: 300px;
	-webkit-animation: off-on 15s infinite linear;
	animation: off-on 15s infinite linear;
}

.single-footer-widget {
	margin-bottom: 30px;
}

.single-footer-widget .logo {
	margin-bottom: 20px;
	display: inline-block;
}

.single-footer-widget .logo .main-logo {
	display: block;
}

.single-footer-widget .logo .white-logo {
	display: none;
}

.single-footer-widget h3 {
	font-size: 20px;
	margin-bottom: 20px;
}

.single-footer-widget .info {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.single-footer-widget .info li {
	margin-bottom: 15px;
	line-height: 26px;
}

.single-footer-widget .info li:last-child {
	margin-bottom: 0;
}

.single-footer-widget .info li span {
	/* color: #098b99; */
	color: #922337;
}

.single-footer-widget .import-link {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.single-footer-widget .import-link li {
	margin-bottom: 15px;
}

.single-footer-widget .import-link li:last-child {
	margin-bottom: 0;
}

.single-footer-widget .import-link li a i {
	position: relative;
	top: 2px;
}

.single-footer-widget .socila-link {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	margin-top: 20px;
}

.single-footer-widget .socila-link li {
	margin-right: 10px;
	display: inline-block;
}

.single-footer-widget .socila-link li:last-child {
	margin-right: 0;
}

.single-footer-widget .socila-link li a {
	width: 50px;
	height: 50px;
	line-height: 54px;
	text-align: center;
	display: inline-block;
	color: #ffffff;
	background-color: #3b5998;
	border-radius: 50%;
}

.single-footer-widget .socila-link li a:hover {
	background-color: #098b99;
}

.single-footer-widget .socila-link li:nth-child(2) a {
	background-color: #1da1f2;
}

.single-footer-widget .socila-link li:nth-child(2) a:hover {
	background-color: #098b99;
}

.single-footer-widget .socila-link li:nth-child(3) a {
	background-color: #0077b5;
}

.single-footer-widget .socila-link li:nth-child(3) a:hover {
	background-color: #098b99;
}

.single-footer-widget .socila-link li:nth-child(4) a {
	background-color: #ff0000;
}

.single-footer-widget .socila-link li:nth-child(4) a:hover {
	background-color: #098b99;
}

.single-footer-widget.pl-40 {
	padding-left: 40px;
}

.copy-right-area {
	padding-bottom: 20px;
	text-align: center;
}

.copy-right-area p {
	border-top: 1px solid #e3eef0;
	padding-top: 20px;
}

.copy-right-area p a {
	/* color: #098b99; */
	color: #922337;
}

/*
Pages Banner Area Style
======================================================*/
.pages-banner-area {
	/* background: linear-gradient(96.41deg, #6cc17e 10.61%, #098b99 70.66%); */
	background: linear-gradient(315deg, #1e2046 0%, #272360 20%, #322e78 50%, #3d3988 100%);
	position: relative;
	z-index: 1;
	overflow: hidden;
}

/* .pages-banner-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    248.11deg,
    rgba(255, 255, 255, 0.3) 92.66%,
    rgba(255, 255, 255, 0.3) 99.52%
  );
  opacity: 0.5;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
}

.pages-banner-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    104.67deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.7;
  -webkit-box-shadow: inset 0px -22px 24px rgba(255, 255, 255, 0.25);
  box-shadow: inset 0px -22px 24px rgba(255, 255, 255, 0.25);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
} */

.pages-banner-area .shape {
	position: absolute;
	z-index: -1;
}

.pages-banner-area .shape.shape-1 {
	bottom: -80px;
	left: 100px;
	-webkit-animation: off-on 5s infinite linear;
	animation: off-on 5s infinite linear;
}

.pages-banner-area .shape.shape-2 {
	bottom: 80px;
	right: 200px;
	-webkit-animation: off-on 10s infinite linear;
	animation: off-on 10s infinite linear;
}

.pages-banner-area .shape.shape-3 {
	top: -50px;
	right: -50px;
	-webkit-animation: off-on 15s infinite linear;
	animation: off-on 15s infinite linear;
}

.pages-banner-content {
	text-align: center;
}

.pages-banner-content h2 {
	font-size: 60px;
	color: #ffffff;
}

.pages-banner-content ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.pages-banner-content ul li {
	display: inline-block;
	color: #ffffff;
	position: relative;
	margin-right: 30px;
}

.pages-banner-content ul li::before {
	content: "";
	position: absolute;
	top: 10px;
	right: -20px;
	width: 6px;
	height: 6px;
	background-color: #ffffff;
	border-radius: 50px;
}

.pages-banner-content ul li:last-child {
	margin-right: 0;
}

.pages-banner-content ul li:last-child::before {
	display: none;
}

.pages-banner-content ul li span {
	color: #fec400;
}

.pages-banner-content ul li a {
	color: #ffffff;
}

.pages-banner-content ul li a:hover {
	color: #fec400;
}

/*
Sidebar Widget Area Style
======================================================*/
.sidebar-widget {
	margin-bottom: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid #eaeaea;
}

.sidebar-widget:last-child {
	margin-bottom: 0;
	border-bottom: none;
	padding-bottom: 0;
}

.sidebar-widget h3 {
	font-size: 20px;
	margin-bottom: 20px;
}

.sidebar-widget ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.sidebar-widget .src-form {
	position: relative;
}

.sidebar-widget .src-form .form-control {
	background-color: #ffffff;
}

.sidebar-widget .src-form .form-control:focus {
	border-color: #098b99;
}

.sidebar-widget .src-form .src-btn {
	position: absolute;
	top: 13px;
	right: 18px;
	background-color: transparent;
	font-size: 20px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.sidebar-widget .src-form .src-btn:hover {
	color: #098b99;
}

.sidebar-widget.categories ul li {
	margin-bottom: 10px;
}

.sidebar-widget.categories ul li:last-child {
	margin-bottom: 0;
}

.sidebar-widget.categories ul li a {
	position: relative;
	display: block;
	font-weight: 700;
	font-size: 15px;
	color: #0d0c0c;
}

.sidebar-widget.categories ul li a:hover {
	color: #098b99;
}

.sidebar-widget.categories ul li a::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 16px;
	height: 16px;
	border: 1px solid #098b99;
	border-radius: 2px;
}

.sidebar-widget.categories ul li a::before {
	content: "";
	position: absolute;
	top: 3px;
	right: 3px;
	width: 10px;
	height: 10px;
	/* background-color: #098b99; */
	background-color: #922337;
	border-radius: 2px;
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.sidebar-widget.categories ul li a:hover::before {
	opacity: 1;
}

.sidebar-widget.categories ul li a.active::before {
	opacity: 1;
}

.sidebar-widget.recent-post .item {
	position: relative;
	margin-bottom: 20px;
	padding-left: 115px;
}

.sidebar-widget.recent-post .item:last-child {
	margin-bottom: 0;
}

.sidebar-widget.recent-post .item .thumb {
	top: 0;
	left: 0;
	width: 100px;
	float: unset;
	height: 100%;
	display: block;
	position: absolute;
}

.sidebar-widget.recent-post .item .thumb .fullimage {
	width: 100px;
	height: 100%;
	display: inline-block;
	background-repeat: no-repeat;
	background-size: cover !important;
	background-position: center center !important;
}

.sidebar-widget.recent-post .item .thumb .fullimage.bg-1 {
	/* background-image: url("/public/images/blog/blog-1.jpg"); */
}

.sidebar-widget.recent-post .item .thumb .fullimage.bg-2 {
	/* background-image: url("/public/images/blog/blog-2.jpg"); */
}

.sidebar-widget.recent-post .item .thumb .fullimage.bg-3 {
	/* background-image: url("/public/images/blog/blog-3.jpg"); */
}

.sidebar-widget.recent-post .item .thumb .fullimage.bg-4 {
	/* background-image: url("/public/images/blog/blog-4.jpg"); */
}

.sidebar-widget.recent-post .item .info .title {
	font-size: 15px;
	margin-bottom: 7px;
	font-weight: 700;
}

.sidebar-widget.recent-post .item .info .title a {
	color: #0d0c0c;
}

.sidebar-widget.recent-post .item .info .title a:hover {
	color: #098b99;
}

.sidebar-widget.recent-post .item .info .date {
	padding-left: 0;
	position: relative;
	font-size: 14px;
	color: #098b99;
	display: block;
	margin-bottom: 8px;
}

.sidebar-widget.recent-post .item .info .date i {
	position: relative;
	top: 2px;
}

.sidebar-widget.tags ul {
	margin-bottom: -10px;
}

.sidebar-widget.tags ul li {
	display: inline-block;
	margin-bottom: 10px;
	padding-left: 0;
	margin-right: 10px;
}

.sidebar-widget.tags ul li:last-child {
	margin-bottom: 0;
	margin-right: 0;
}

.sidebar-widget.tags ul li::before {
	display: none;
}

.sidebar-widget.tags ul li a {
	background-color: #f2f0ef;
	padding: 5px 12px;
	display: inline-block;
	font-size: 15px;
	border-radius: 5px;
	font-weight: 600;
	color: #0d0c0c;
}

.sidebar-widget.tags ul li a:hover {
	background-color: #098b99;
	color: #ffffff;
	border-color: #098b99;
}

/*
Instructor Area Style
======================================================*/
.single-instructor {
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	margin-bottom: 30px;
}

.single-instructor img {
	border-radius: 20px 20px 0 0;
}

.single-instructor .instructor-info {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 30px;
	margin-bottom: 0;
	list-style-type: none;
	border-radius: 0 0 20px 20px;
}

.single-instructor .instructor-info h3 {
	font-size: 20px;
}

.single-instructor .instructor-info h3 a {
	color: #0d0c0c;
}

.single-instructor .instructor-info span {
	font-size: 15px;
}

.single-instructor .instructor-info .read-btn {
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	border-radius: 50%;
	display: inline-block;
	font-size: 30px;
	color: #0d0c0c;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-instructor .instructor-info .read-btn i {
	position: relative;
	right: -2px;
}

.single-instructor .instructor-info .read-btn:hover {
	background-color: #098b99;
	color: #ffffff;
}

.single-instructor:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

.single-instructor:hover .instructor-info h3 a {
	color: #098b99;
}

/*
Instructor Details Area Style
======================================================*/
.instructor-details {
	position: relative;
	z-index: 1;
}

.instructor-details .shape {
	position: absolute;
	z-index: -1;
}

.instructor-details .shape.shape-1 {
	bottom: 0;
	right: 250px;
	-webkit-animation: off-on 5s infinite linear;
	animation: off-on 5s infinite linear;
}

.instructor-details-content {
	max-width: 600px;
	margin-left: 50px;
}

.instructor-details-content h3 {
	font-size: 20px;
}

.instructor-details-content span {
	display: block;
	margin-bottom: 50px;
}

.instructor-details-content p {
	margin-bottom: 50px;
}

.instructor-details-content ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.instructor-details-content ul li {
	display: inline-block;
	margin-right: 10px;
}

.instructor-details-content ul li:last-child {
	margin-right: 0;
}

.instructor-details-content ul li span {
	position: relative;
	top: -2px;
	margin-bottom: 0;
	color: #0d0c0c;
	font-weight: 600;
}

.instructor-details-content ul li a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background: linear-gradient(133.65deg, #6cc17e 2.24%, #098b99 100%);
	color: #ffffff;
	display: inline-block;
	border-radius: 50px;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.instructor-details-content ul li a::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background-color: #098b99;
	border-radius: 50px;
	z-index: -1;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.instructor-details-content ul li a:hover::before {
	right: auto;
	left: 0;
	width: 100%;
}

/*
Cart Area Style
======================================================*/
.your-cart span {
	color: #0d0c0c;
}

.single-cart {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.single-cart .single-cart-list {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 30px;
	border-radius: 5px;
	margin-bottom: 30px;
}

.single-cart .single-cart-list:last-child {
	margin-bottom: 0;
}

.single-cart .single-cart-list .single-cart-content img {
	margin-right: 20px;
	width: 115px;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents h3 {
	font-size: 16px;
	margin-bottom: 5px;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents h3 a {
	color: #0d0c0c;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents p {
	margin-bottom: 0;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents p a {
	color: #0d0c0c;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .rating {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .rating li {
	display: inline-block;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .rating li span {
	color: #fec400;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .rating li i {
	color: #fec400;
	position: relative;
	top: 2px;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .lectures {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .lectures li {
	display: inline-block;
	color: #0d0c0c;
	margin-right: 20px;
	position: relative;
	font-size: 15px;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .lectures li::before {
	content: "";
	position: absolute;
	top: 9px;
	right: -14px;
	width: 6px;
	height: 6px;
	background-color: #098b99;
	border-radius: 50%;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .lectures li:last-child {
	margin-right: 0;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .lectures li:last-child::before {
	display: none;
}

.single-cart .single-cart-list .single-cart-content .single-cart-contents .lectures li span {
	color: #646464;
}

.single-cart .prw {
	text-align: right;
}

.single-cart .prw h4 {
	color: #098b99;
	font-size: 24px;
	margin-bottom: 20px;
}

.single-cart .prw h4 del {
	font-size: 16px;
	font-weight: 400;
	color: #646464;
}

.single-cart .prw .wis-rem .remove {
	color: #098b99;
	background-color: transparent;
	font-size: 14px;
}

.single-cart .prw .wis-rem .remove:hover {
	color: #ff0000;
}

.single-cart .prw .wis-rem .wish {
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background-color: #f0bf79;
	color: #ffffff;
	border-radius: 5px;
	font-size: 25px;
	margin-left: 10px;
}

.single-cart .prw .wis-rem .wish:hover {
	background-color: #098b99;
}

.single-cart:hover .single-cart-content .single-cart-contents h3 a {
	color: #098b99;
}

.cart-total {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 30px;
	border-radius: 5px;
}

.cart-total h3 {
	font-size: 25px;
	margin-bottom: 20px;
}

.cart-total h3 span {
	color: #098b99;
	float: right;
}

.cart-total ul {
	padding-left: 0;
	margin-bottom: 20px;
	list-style-type: none;
}

.cart-total ul li {
	text-align: right;
	margin-bottom: 10px;
}

.cart-total ul li span {
	color: #0d0c0c;
}

.cart-total ul li:last-child {
	margin-bottom: 0;
}

.cart-total .default-btn {
	width: 100%;
	display: block;
}

/*
Register Area Style
======================================================*/
.register-img {
	text-align: center;
}

.register-form h2 {
	font-size: 40px;
	margin-bottom: 40px;
}

.register-form .form-group {
	margin-bottom: 30px;
}

.register-form .default-btn {
	display: block;
	width: 100%;
}

.register-form .form-check-input {
	border-color: #098b99;
}

.register-form .form-check-input:checked {
	background-color: #098b99;
	border-color: #098b99;
}

.register-form .form-check-input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.register-tab {
	text-align: center;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 10px;
	border: none;
	border-radius: 50px;
	margin-bottom: 30px;
}

.register-tab .nav-item {
	border: none;
}

.register-tab .nav-item .nav-link {
	border: none;
	width: 100%;
	display: block;
	padding: 10px 70px;
	border-radius: 5px;
	color: #0d0c0c;
	font-weight: 600;
	font-size: 18px;
	border-radius: 50px;
}

.register-tab .nav-item .nav-link.active {
	background: linear-gradient(133.65deg, #6cc17e 2.24%, #098b99 100%);
	color: #ffffff;
}

/*
Contact Info Area Style
======================================================*/
.single-contact-info {
	background-color: #b5d3f0;
	padding: 30px;
	border-radius: 20px 20px 0 0;
	margin-bottom: 30px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-contact-info.bg-f3dfc1 {
	background-color: #f3dfc1;
}

.single-contact-info.bg-a8e3da {
	background-color: #a8e3da;
}

.single-contact-info img {
	margin-right: 20px;
}

.single-contact-info h3 {
	font-size: 20px;
	margin-bottom: 20px;
}

.single-contact-info ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.single-contact-info ul li {
	margin-bottom: 5px;
}

.single-contact-info ul li:last-child {
	margin-bottom: 0;
}

.single-contact-info:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

/* 
Main Contact Area Style
=====================================================*/
.contact-area {
	position: relative;
	z-index: 1;
}

.contact-area .shape {
	position: absolute;
	z-index: -1;
}

.contact-area .shape.shape-1 {
	top: 100px;
	left: 200px;
	-webkit-animation: off-on 5s infinite linear;
	animation: off-on 5s infinite linear;
}

.contact-area .shape.shape-2 {
	bottom: 100px;
	right: 200px;
	-webkit-animation: off-on 10s infinite linear;
	animation: off-on 10s infinite linear;
}

.contact-form {
	margin: auto;
	max-width: 860px;
}

.contact-form .form-group {
	margin-bottom: 30px;
}

.contact-form .form-check {
	padding-left: 12px;
}

.contact-form .form-check label {
	margin-bottom: 0;
}

.contact-form a {
	color: #098b99;
}

.contact-form .form-check-input {
	border-color: #098b99;
}

.contact-form .form-check-input:checked {
	background-color: #098b99;
	border-color: #098b99;
}

.contact-form .form-check-input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.list-unstyled {
	color: #dc3545;
	margin-top: 10px;
	font-size: 14px;
}

.hidden {
	display: none;
}

#msgSubmit {
	margin-top: 30px;
	font-size: 20px;
}

/*
FAQ Area CSS
=====================================================*/
.faq-area {
	position: relative;
	z-index: 1;
}

.faq-area .shape {
	position: absolute;
	z-index: -1;
}

.faq-area .shape.shape-1 {
	top: 180px;
	right: 300px;
	-webkit-animation: off-on 5s infinite linear;
	animation: off-on 5s infinite linear;
}

.accordion-item {
	border-radius: 5px;
	border: none;
	max-width: 860px;
	margin: auto;
	margin-bottom: 20px;
}

.accordion-item:last-child {
	margin-bottom: 0;
}

.accordion-item .accordion-header .accordion-button {
	border: 1px solid #f5f5f5;
	border-radius: 5px;
	font-size: 18px;
	font-weight: 700;
}

.accordion-item .accordion-header .accordion-button::after {
	/* background-image: url("/public/images/icon/up-arrow-b.svg"); */
	background-size: 70%;
	top: 7px;
	position: relative;
}

.accordion-item .accordion-header .accordion-button:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed) {
	background-color: #f2f0ef;
	color: #0d0c0c;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 5px 5px 0 0;
	border-color: #ebf0f3;
	border-bottom: 2px solid #098b99;
	border-radius: 0 0 10px 10px;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
	/* background-image: url("/public/images/icon/up-arrow.svg"); */
	top: -5px;
	position: relative;
}

.accordion-item .accordion-body {
	border: none;
	border-top: none;
	border-radius: 0 0 5px 5px;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.accordion-item:first-of-type .accordion-button {
	border-radius: 5px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-radius: 5px;
}

.faq-tab {
	text-align: center;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 10px;
	border: none;
	border-radius: 50px;
	margin-bottom: 30px;
	max-width: 400px;
	margin: auto;
	margin-bottom: 30px;
}

.faq-tab .nav-item {
	border: none;
}

.faq-tab .nav-item .nav-link {
	border: none;
	width: 100%;
	display: block;
	padding: 10px 58px;
	border-radius: 50px;
	color: #0d0c0c;
	font-weight: 600;
	font-size: 18px;
}

.faq-tab .nav-item .nav-link.active {
	background: linear-gradient(133.65deg, #6cc17e 2.24%, #098b99 100%);
	color: #ffffff;
}

/*
Feature Area CSS
=====================================================*/
.feature-details-content {
	max-width: 860px;
	margin: auto;
}

.feature-details-content h2 {
	font-size: 35px;
	margin-bottom: 15px;
}

.feature-details-content ul {
	padding-left: 0;
	margin-top: 5px;
	margin-bottom: 30px;
	list-style-type: none;
}

.feature-details-content ul li {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 24px;
	cursor: pointer;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.feature-details-content ul li:last-child {
	margin-bottom: 0;
}

.feature-details-content ul li h3 {
	font-size: 16px;
	margin-bottom: 0;
	margin-left: 15px;
}

.feature-details-content ul li:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

.feature-details-content .feature-video {
	position: relative;
	margin-bottom: 30px;
}

.feature-details-content .feature-video .video-btns {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	text-align: center;
}

/*
Blog Details Area CSS
=====================================================*/
.blog-details-content {
	max-width: 860px;
	margin: auto;
}

.blog-details-content .gap-mb-50 {
	margin-bottom: 30px;
}

.blog-details-content h2 {
	font-size: 35px;
	margin-bottom: 25px;
}

.blog-details-content .tags {
	padding-left: 0;
	margin-bottom: 20px;
	list-style: none;
}

.blog-details-content .tags li {
	display: inline-block;
	margin-right: 10px;
}

.blog-details-content .tags li:last-child {
	margin-right: 0;
}

.blog-details-content .tags li a {
	background-color: #f2f0ef;
	display: inline-block;
	padding: 10px 12px;
	border-radius: 5px;
}

.blog-details-content .poster-info {
	padding-left: 0;
	margin-bottom: 30px;
	list-style-type: none;
}

.blog-details-content .poster-info li {
	display: inline-block;
	margin-right: 40px;
	position: relative;
}

.blog-details-content .poster-info li::before {
	content: "";
	position: absolute;
	top: 9px;
	right: -24px;
	width: 5px;
	height: 5px;
	background-color: #098b99;
	border-radius: 50px;
}

.blog-details-content .poster-info li:last-child {
	margin-right: 0;
}

.blog-details-content .poster-info li:last-child::before {
	display: none;
}

.blog-details-content .poster-info li a {
	color: #0d0c0c;
}

.blog-details-content .poster-info li img {
	position: relative;
	top: -2px;
	margin-right: 5px;
	border-radius: 50px;
}

.blog-details-content .blog-post-img {
	margin-bottom: 30px;
}

.blog-details-content p a {
	color: #098b99;
}

.blog-details-content blockquote {
	background-color: #f6fafb;
	padding: 30px;
	padding-left: 70px;
	border-left: 2px solid #098b99;
	margin-top: 30px;
	margin-bottom: 30px;
}

.blog-details-content blockquote p {
	font-size: 20px;
	color: #0d0c0c;
	position: relative;
}

.blog-details-content blockquote p i {
	position: absolute;
	top: -10px;
	left: -25px;
	color: #098b99;
}

.blog-details-content h3 {
	font-size: 25px;
	margin-bottom: 10px;
}

.blog-details-content .blog-video {
	position: relative;
}

.blog-details-content .blog-video .video-btns {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.blog-details-content .social-link {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
	text-align: center;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	padding-top: 20px;
	padding-bottom: 20px;
}

.blog-details-content .social-link li {
	display: inline-block;
	margin-right: 10px;
}

.blog-details-content .social-link li:last-child {
	margin-right: 0;
}

.blog-details-content .social-link li span {
	font-size: 20px;
	color: #0d0c0c;
	font-weight: 600;
	position: relative;
	top: -2px;
}

.blog-details-content .social-link li a {
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	background: linear-gradient(133.65deg, #6cc17e 2.24%, #098b99 100%);
	color: #ffffff;
	border-radius: 50px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.blog-details-content .social-link li a:hover {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

.blog-details-content .post-author {
	margin-bottom: 20px;
}

.blog-details-content .post-author .post-avatar img {
	border-radius: 50px;
	position: relative;
	top: -5px;
	margin-right: 10px;
}

.blog-details-content .post-author .post-avatar .author-info h4 {
	font-size: 18px;
}

.blog-details-content .post-author .post-avatar .author-info span {
	font-size: 14px;
}

.blog-details-content .related-title {
	margin-bottom: 20px;
}

.blog-details-content .related-title h3 {
	font-size: 25px;
	margin-bottom: 0;
}

.blog-details-content .single-blog .blog-content h3 {
	font-size: 18px;
}

.blog-details-content .comments {
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	margin-top: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
}

.blog-details-content .comments h3 {
	margin-bottom: 20px;
}

.blog-details-content .comments ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.blog-details-content .comments ul li {
	margin-bottom: 30px;
}

.blog-details-content .comments ul li:last-child {
	margin-bottom: 0;
}

.blog-details-content .comments ul li img {
	border-radius: 50%;
	margin-right: 20px;
}

.blog-details-content .comments ul li .comment-onfo h4 {
	font-size: 16px;
	margin-bottom: 0;
}

.blog-details-content .comments ul li .comment-onfo h4 span {
	font-weight: 400;
	color: #646464;
	font-size: 14px;
	margin-left: 20px;
}

.blog-details-content .comments ul li .comment-onfo .read-more {
	margin-bottom: 5px;
}

.blog-details-content .comments ul li.reply {
	margin-left: 100px;
}

.blog-details-content .leave-comment p {
	margin-bottom: 25px;
}

.blog-details-content .leave-comment .form-group {
	margin-bottom: 30px;
}

.blog-details-content .leave-comment .form-check-input {
	border-color: #098b99;
}

.blog-details-content .leave-comment .form-check-input:checked {
	background-color: #098b99;
	border-color: #098b99;
}

.blog-details-content .leave-comment .form-check-input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.blog-details-content .leave-comment .default-btn {
	width: auto;
}

/* 
Checkout Area Style
=====================================================*/
.checkout-wrap .billing-details {
	padding: 30px;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	background-color: #ffffff;
	border-radius: 5px;
}

.checkout-wrap .billing-details h3 {
	margin-bottom: 20px;
	position: relative;
	padding-bottom: 10px;
	border-bottom: 1px solid #ebebeb;
	font-size: 25px;
	position: relative;
}

.checkout-wrap .billing-details .form-group {
	margin-bottom: 20px;
}

.checkout-wrap .billing-details .form-group .form-control {
	background-color: #ffffff;
	border-color: #ebebeb;
}

.checkout-wrap .billing-details .form-group .form-control::-webkit-input-placeholder {
	font-size: 16px;
}

.checkout-wrap .billing-details .form-group .form-control:-ms-input-placeholder {
	font-size: 16px;
}

.checkout-wrap .billing-details .form-group .form-control::-ms-input-placeholder {
	font-size: 16px;
}

.checkout-wrap .billing-details .form-group .form-control::placeholder {
	font-size: 16px;
}

.checkout-wrap .billing-details .form-group .form-control:focus {
	border-color: #098b99;
}

.checkout-wrap .billing-details .form-group .form-control.form-select {
	font-size: 16px;
	color: #646464;
}

.checkout-wrap .billing-details .form-group label {
	display: block;
	color: #646464;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 600;
}

.checkout-wrap .billing-details .form-group label .required {
	color: #646464;
}

.checkout-wrap .billing-details .form-group textarea {
	padding-top: 13px;
}

.checkout-wrap .billing-details .form-check {
	margin-bottom: 20px;
	cursor: pointer;
}

.checkout-wrap .billing-details .form-check .form-check-label {
	color: #646464;
	font-weight: 600;
	font-size: 14px;
}

.checkout-wrap .billing-details .form-check label {
	position: relative;
	top: 1px;
}

.checkout-wrap .billing-details .col-lg-12:last-child .form-group {
	margin-bottom: 0;
}

.checkout-wrap .faq-accordion {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 30px;
	margin-top: 30px;
	border-radius: 5px;
}

.checkout-wrap .faq-accordion h3 {
	font-size: 25px;
	margin-bottom: 20px;
}

.checkout-wrap .faq-accordion .accordion-button {
	background-color: transparent;
	font-size: 16px;
	padding-left: 20px !important;
	border: none;
}

.checkout-wrap .faq-accordion .accordion-button::after {
	content: "\EB7D";
	font-family: remixicon !important;
	background-image: unset;
	position: absolute;
	left: 0;
	top: 1px;
}

.checkout-wrap .faq-accordion .accordion-button:not(.collapsed)::after {
	background-image: unset;
	background-color: transparent;
	content: "\EB7C";
	font-family: remixicon !important;
	color: #098b99;
	position: absolute;
	top: 0;
	left: 0;
}

.checkout-wrap .faq-accordion .accordion-button::after {
	background-color: transparent;
	color: #098b99;
	border: none;
	-webkit-transform: rotate(0);
	transform: rotate(0);
	left: -4px;
}

.checkout-wrap .faq-accordion .form-check {
	margin-bottom: 30px;
}

.checkout-wrap .faq-accordion .default-btn {
	width: 100%;
	display: block;
}

.checkout-wrap .faq-accordion .accordion-button {
	border: none;
	background-color: transparent;
	padding: 0;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 15px;
}

.checkout-wrap .faq-accordion .accordion-button:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.checkout-wrap .faq-accordion .accordion-button:not(.collapsed) {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
}

.checkout-wrap .faq-accordion .accordion-item {
	border: none;
	background-color: transparent;
	margin-bottom: 20px;
}

.checkout-wrap .faq-accordion .accordion-item:last-child {
	margin-bottom: 0;
}

.checkout-wrap .faq-accordion .accordion-body {
	padding: 0;
	padding-top: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.checkout-wrap .faq-accordion .accordion-body p {
	font-size: 15px;
}

.checkout-wrap .form-check-input {
	border-color: #098b99;
}

.checkout-wrap .form-check-input:checked {
	background-color: #098b99;
	border-color: #098b99;
}

.checkout-wrap .form-check-input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.checkout-wrap .default-btn {
	width: auto;
}

/* 
Main Content Text Style
=====================================================*/
.main-content-text h1,
.main-content-text h2,
.main-content-text h3,
.main-content-text h4,
.main-content-text h5,
.main-content-text h6 {
	margin-bottom: 15px;
}

.main-content-text h1 {
	font-size: 30px;
}

.main-content-text h2 {
	font-size: 26px;
}

.main-content-text h3 {
	font-size: 22px;
}

.main-content-text h4 {
	font-size: 22px;
}

.main-content-text h5 {
	font-size: 20px;
}

.main-content-text h6 {
	font-size: 18px;
}

.main-content-text .gap-20 {
	margin-bottom: 30px;
}

.main-content-text ul {
	padding: 0;
	list-style-type: none;
	margin-bottom: 0;
}

.main-content-text ul li {
	position: relative;
	margin-bottom: 10px;
	padding-left: 35px;
}

.main-content-text ul li:last-child {
	margin-bottom: 0;
}

.main-content-text ul li i {
	position: absolute;
	top: -6px;
	left: 0;
	color: #098b99;
	font-size: 25px;
}

.main-content-text ol {
	padding-left: 18px;
}

.main-content-text ol li {
	margin-bottom: 10px;
}

.main-content-text ol li::marker {
	color: #098b99;
}

.main-content-text ol li:last-child {
	margin-bottom: 0;
}

.main-content-text a {
	color: #098b99;
}

.main-content-text a:hover {
	text-decoration: underline;
}

.main-content-text .content-gap-mb-20 {
	margin-bottom: 20px;
}

.main-content-text .content-gap-mb-30 {
	margin-bottom: 30px;
}

/*
Error Area CSS
=====================================================*/
.error-area {
	background-color: #ffffff;
}

.error-content {
	text-align: center;
	position: relative;
}

.error-content h3 {
	font-size: 30px;
}

/*
Coming Soon Area Style
=====================================================*/
.coming-soon-area {
	height: 100vh;
	/* background-image: url("/public/images/coming-soon-bg.png"); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.coming-soon-content {
	max-width: 700px;
	border-radius: 5px;
	overflow: hidden;
	text-align: center;
	padding: 60px;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	margin: auto;
}

.coming-soon-content h2 {
	font-size: 40px;
	margin-top: -10px;
	margin-bottom: 0;
}

.coming-soon-content #timer div {
	border: 2px solid #098b99;
	color: #098b99;
	width: 85px;
	height: 85px;
	border-radius: 5px;
	font-size: 30px;
	font-weight: 800;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.coming-soon-content #timer div:last-child {
	margin-bottom: 0;
}

.coming-soon-content #timer div span {
	display: block;
	margin-top: -2px;
	font-size: 14px;
	font-weight: 600;
}

.coming-soon-content form {
	position: relative;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
}

.coming-soon-content form .form-group {
	margin-bottom: 25px;
	width: 100%;
	position: relative;
}

.coming-soon-content form .form-group .label-title {
	margin-bottom: 0;
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	pointer-events: none;
	width: 100%;
	height: 100%;
	color: #0d0c0c;
}

.coming-soon-content form .form-group .label-title i {
	position: absolute;
	left: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	top: 9px;
	font-size: 22px;
}

.coming-soon-content form .form-group .label-title::before {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 2px;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	background: #098b99;
}

.coming-soon-content form .form-group .input-newsletter {
	border-radius: 0;
	border: 1px solid #eeeeee;
	padding: 10px 20px;
	color: #0d0c0c;
	height: 50px;
	display: block;
	width: 100%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	font-size: 16px;
	font-weight: 400;
	background-color: #ffffff;
}

.coming-soon-content form .form-group .input-newsletter::-webkit-input-placeholder {
	color: #646464;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coming-soon-content form .form-group .input-newsletter:-ms-input-placeholder {
	color: #646464;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coming-soon-content form .form-group .input-newsletter::-ms-input-placeholder {
	color: #646464;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coming-soon-content form .form-group .input-newsletter::placeholder {
	color: #646464;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coming-soon-content form .form-group .input-newsletter:focus {
	border-color: #098b99;
	outline: 0 !important;
}

.coming-soon-content form .form-group .input-newsletter:focus::-webkit-input-placeholder {
	color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus:-ms-input-placeholder {
	color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::-ms-input-placeholder {
	color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::placeholder {
	color: transparent;
}

.coming-soon-content form .validation-danger {
	margin-top: 15px;
	color: #ff0000;
}

.coming-soon-content form .validation-success {
	margin-top: 15px;
}

.coming-soon-content form p {
	margin-bottom: 0;
	margin-top: 20px;
}

.coming-soon-content .newsletter-form .default-btn {
	margin-top: 0;
	position: absolute;
	top: 0;
	right: 0;
	height: 49px;
}

/*
Course Details Area Style
=====================================================*/
.course-details-content .gap-mb-30 {
	margin-bottom: 30px;
}

.course-details-content .gap-mb-50 {
	margin-bottom: 30px;
}

.course-details-content .title {
	font-size: 35px;
	margin-bottom: 25px;
}

.course-details-content .best-seller {
	padding-left: 0;
	list-style-type: none;
}

.course-details-content .best-seller li {
	display: inline-block;
	margin-right: 30px;
	position: relative;
}

.course-details-content .best-seller li::before {
	content: "";
	position: absolute;
	top: 7px;
	right: -24px;
	width: 8px;
	height: 8px;
	background-color: #098b99;
	border-radius: 50%;
}

.course-details-content .best-seller li:nth-child(1)::before {
	top: 12px;
}

.course-details-content .best-seller li:last-child {
	margin-right: 0;
}

.course-details-content .best-seller li:last-child::before {
	display: none;
}

.course-details-content .best-seller li a {
	background-color: #d4eef1;
	color: #098b99;
	display: inline-block;
	padding: 5px 15px;
	border-radius: 5px;
	margin-right: 20px;
}

.course-details-content .best-seller li i {
	color: #fec400;
	position: relative;
	top: 3px;
}

.course-details-content .best-seller li .rating {
	color: #646464;
}

.course-details-content .best-seller li span {
	color: #0d0c0c;
	display: inline-block;
}

.course-details-content .admin-info img {
	margin-right: 10px;
	position: relative;
	top: -5px;
	width: 36px;
	height: 36px;
	border-radius: 100%;
}

.course-details-content .admin-info span {
	font-size: 15px;
	margin-right: 5px;
}

.course-details-content .admin-info a {
	color: #098b99;
	font-size: 15px;
}

.course-details-content .this-course-content h1,
.course-details-content .this-course-content h2,
.course-details-content .this-course-content h3,
.course-details-content .this-course-content h4,
.course-details-content .this-course-content h5,
.course-details-content .this-course-content h6 {
	margin-bottom: 10px;
}

.course-details-content .this-course-content h1 {
	font-size: 27px;
}

.course-details-content .this-course-content h2 {
	font-size: 26px;
}

.course-details-content .this-course-content h3 {
	font-size: 23px;
}

.course-details-content .this-course-content h4 {
	font-size: 20px;
}

.course-details-content .this-course-content h5 {
	font-size: 18px;
}

.course-details-content .this-course-content h6 {
	font-size: 16px;
}

.course-details-content .this-course-content p {
	margin-bottom: 10px;
}

.course-details-content .this-course-content p:last-child {
	margin-bottom: 0;
}

.course-details-content .this-course-content ul li,
.course-details-content .this-course-content ol li {
	margin-bottom: 10px;
}

.course-details-content .this-course-content ul li:last-child,
.course-details-content .this-course-content ol li:last-child {
	margin-bottom: 0;
}

.course-details-content .course-contents {
	margin-bottom: 30px;
}

.course-details-content .course-contents h1,
.course-details-content .course-contents h2,
.course-details-content .course-contents h3,
.course-details-content .course-contents h4,
.course-details-content .course-contents h5,
.course-details-content .course-contents h6 {
	margin-bottom: 10px;
}

.course-details-content .course-contents h1 {
	font-size: 27px;
}

.course-details-content .course-contents h2 {
	font-size: 26px;
}

.course-details-content .course-contents h3 {
	font-size: 23px;
}

.course-details-content .course-contents h4 {
	font-size: 20px;
}

.course-details-content .course-contents h5 {
	font-size: 18px;
}

.course-details-content .course-contents h6 {
	font-size: 16px;
}

.course-details-content .course-contents p {
	margin-bottom: 10px;
}

.course-details-content .course-contents p:last-child {
	margin-bottom: 0;
}

.course-details-content .course-contents ul li,
.course-details-content .course-contents ol li {
	margin-bottom: 10px;
}

.course-details-content .course-contents ul li:last-child,
.course-details-content .course-contents ol li:last-child {
	margin-bottom: 0;
}

.course-details-content .course-contents .course-lisrs {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.course-details-content .course-contents .course-lisrs li {
	display: inline-block;
	position: relative;
	padding-left: 20px;
	margin-bottom: 20px;
}

.course-details-content .course-contents .course-lisrs li:last-child {
	margin-bottom: 0;
}

.course-details-content .course-contents .course-lisrs li i {
	color: #098b99;
	position: absolute;
	top: -1px;
	left: 0;
}

.course-details-content .course-contents .course-title {
	margin-bottom: 20px;
}

.course-details-content .course-contents .course-title h3 {
	font-size: 25px;
	margin-bottom: 0;
}

.course-details-content .course-contents .course-title .lectures {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.course-details-content .course-contents .course-title .lectures li {
	display: inline-block;
	margin-right: 30px;
	position: relative;
}

.course-details-content .course-contents .course-title .lectures li::before {
	content: "";
	position: absolute;
	top: 8px;
	right: -22px;
	width: 8px;
	height: 8px;
	background-color: #098b99;
	border-radius: 50%;
}

.course-details-content .course-contents .course-title .lectures li:last-child {
	margin-right: 0;
}

.course-details-content .course-contents .course-title .lectures li:last-child::before {
	display: none;
}

.course-details-content .course-contents .course-title .lectures li span {
	color: #0d0c0c;
}

.course-details-content .course-contents .accordion-item {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.course-details-content .course-contents .accordion-item h2 {
	margin-bottom: 0;
	font-size: 18px;
}

.course-details-content .course-contents .accordion-item h2 span {
	font-weight: 400;
	color: #646464;
	font-size: 16px;
	margin-left: 30px;
	position: relative;
}

.course-details-content .course-contents .accordion-item h2 span::before {
	content: "";
	position: absolute;
	top: 5px;
	right: -18px;
	width: 8px;
	height: 8px;
	background-color: #098b99;
	border-radius: 50%;
}

.course-details-content .course-contents .accordion-item h2 span:last-child::before {
	display: none;
}

.course-details-content .course-contents .accordion-item .accordion-header .accordion-button:not(.collapsed) {
	border-color: #ffffff;
	background-color: #ffffff;
	border-radius: 0;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.course-details-content .course-contents .accordion-item .courses-items {
	border-bottom: 1px solid #efefef;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.course-details-content .course-contents .accordion-item .courses-items:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.course-details-content .course-contents .accordion-item .courses-items h3 {
	font-size: 16px;
	margin-bottom: 0;
}

.course-details-content .course-contents .accordion-item .courses-items h3 span {
	margin-right: 20px;
}

.course-details-content .course-contents .accordion-item .courses-items .courses-info span {
	font-size: 15px;
	color: #6a6c7c;
	position: relative;
	top: -3px;
	margin-right: 10px;
}

.course-details-content .course-contents .accordion-item .courses-items .courses-info a {
	width: 30px;
	height: 30px;
	line-height: 30px;
	display: inline-block;
	background-color: #d4eef1;
	text-align: center;
	border-radius: 50%;
}

.course-details-content .course-contents .accordion-item .courses-items .courses-info a i {
	color: #098b99;
}

.course-details-content .reviews h3 {
	margin-bottom: 20px;
	font-size: 25px;
}

.course-details-content .reviews ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.course-details-content .reviews ul li {
	margin-bottom: 30px;
}

.course-details-content .reviews ul li:last-child {
	margin-bottom: 0;
}

.course-details-content .reviews ul li img {
	border-radius: 50%;
	margin-right: 20px;
}

.course-details-content .reviews ul li .reviews-info h4 {
	font-size: 16px;
	margin-bottom: 0;
}

.course-details-content .reviews ul li .reviews-info h4 span {
	font-weight: 400;
	color: #646464;
	font-size: 14px;
	margin-left: 20px;
}

.course-details-content .reviews ul li .reviews-info .star {
	margin-bottom: 6px;
}

.course-details-content .reviews ul li .reviews-info .star i {
	color: #fec400;
}

.course-details-content .reviews ul li.reply {
	margin-left: 100px;
}

.course-details-content .leave-reviews h3 {
	margin-bottom: 20px;
	font-size: 25px;
}

.course-details-content .leave-reviews .star i {
	color: #fec400;
	cursor: pointer;
}

.course-details-content .leave-reviews p {
	margin-bottom: 25px;
}

.course-details-content .leave-reviews .form-group {
	margin-bottom: 30px;
}

.course-details-content .leave-reviews .form-check-input {
	border-color: #098b99;
}

.course-details-content .leave-reviews .form-check-input:checked {
	background-color: #098b99;
	border-color: #098b99;
}

.course-details-content .leave-reviews .form-check-input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.course-details-content .leave-reviews .default-btn {
	width: auto;
}

.course-details-content .course-tab {
	text-align: center;
	background-color: #f2f0ef;
	padding: 0;
	border: none;
	margin: auto;
	margin-bottom: 30px;
}

.course-details-content .course-tab .nav-item {
	border: none;
}

.course-details-content .course-tab .nav-item .nav-link {
	border: none;
	width: 100%;
	display: block;
	padding: 30px 50px;
	border-radius: 50px;
	color: #0d0c0c;
	font-weight: 600;
	font-size: 18px;
	position: relative;
}

.course-details-content .course-tab .nav-item .nav-link::before {
	content: "";
	position: absolute;
	bottom: 1px;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #098b99;
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.course-details-content .course-tab .nav-item .nav-link.active {
	color: #098b99;
	background-color: transparent;
}

.course-details-content .course-tab .nav-item .nav-link.active::before {
	opacity: 1;
}

.course-details-content .curriculum-content h1,
.course-details-content .curriculum-content h2,
.course-details-content .curriculum-content h3,
.course-details-content .curriculum-content h4,
.course-details-content .curriculum-content h5,
.course-details-content .curriculum-content h6 {
	margin-bottom: 10px;
}

.course-details-content .curriculum-content h1 {
	font-size: 27px;
}

.course-details-content .curriculum-content h2 {
	font-size: 26px;
}

.course-details-content .curriculum-content h3 {
	font-size: 23px;
}

.course-details-content .curriculum-content h4 {
	font-size: 20px;
}

.course-details-content .curriculum-content h5 {
	font-size: 18px;
}

.course-details-content .curriculum-content h6 {
	font-size: 16px;
}

.course-details-content .curriculum-content p {
	margin-bottom: 10px;
}

.course-details-content .curriculum-content p:last-child {
	margin-bottom: 0;
}

.course-details-content .curriculum-content ul li,
.course-details-content .curriculum-content ol li {
	margin-bottom: 10px;
}

.course-details-content .curriculum-content ul li:last-child,
.course-details-content .curriculum-content ol li:last-child {
	margin-bottom: 0;
}

.course-details-content .curriculum-content .title {
	margin-bottom: 30px;
}

.course-details-content .curriculum-content .title li h3 {
	font-size: 20px;
	font-weight: 600;
	display: inline-block;
}

.course-details-content .curriculum-content .title li span {
	float: right;
	color: #0d0c0c;
	font-size: 16px;
	margin-left: 30px;
	font-weight: 500;
}

.course-details-content .curriculum-content .lesson {
	margin-bottom: 40px;
}

.course-details-content .curriculum-content .lesson .border-bottom {
	border-bottom: 1px solid #e1e2e3;
	padding-bottom: 5px;
	margin-bottom: 20px;
}

.course-details-content .curriculum-content .lesson .border-bottom h3 {
	font-size: 18px;
	display: inline-block;
}

.course-details-content .curriculum-content .lesson .border-bottom span {
	float: right;
}

.course-details-content .curriculum-content .lesson .lesson-list {
	margin-bottom: 15px;
}

.course-details-content .curriculum-content .lesson .lesson-list:last-child {
	margin-bottom: 0;
}

.course-details-content .curriculum-content .lesson .lesson-list a {
	color: #646464;
}

.course-details-content .curriculum-content .lesson .lesson-list span {
	float: right;
	margin-left: 15px;
}

.course-details-content .curriculum-content .lesson .lesson-list span.color {
	color: #098b99;
}

.courses-curriculum {
	border: 1px solid #eeeeee;
	border-radius: 5px;
	padding: 30px;
}

.courses-curriculum h3 {
	margin-bottom: 18px;
	font-size: 20px;
	font-weight: 800;
}

.courses-curriculum h3:not(:first-child) {
	margin-top: 30px;
}

.courses-curriculum ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.courses-curriculum ul li {
	display: block;
	background-color: #f8f9f8;
	color: #000000;
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 14.5px;
	padding-bottom: 14.5px;
	margin-bottom: 10px;
}

.courses-curriculum ul li:last-child {
	margin-bottom: 0;
}

.courses-curriculum ul li .courses-name {
	font-size: 14.5px;
	font-weight: 700;
}

.courses-curriculum ul li .courses-meta {
	text-align: right;
}

.courses-curriculum ul li .courses-meta .questions {
	display: inline-block;
	background: #e3f1f2;
	color: #2dbbc4;
	text-transform: lowercase;
	border-radius: 3px;
	margin-right: 6px;
	padding: 2px 10px 1.5px;
	font-size: 14px;
	font-weight: 700;
}

.courses-curriculum ul li .courses-meta .duration {
	display: inline-block;
	background: #f7e7e8;
	color: #098b99;
	text-transform: lowercase;
	border-radius: 3px;
	padding: 2px 10px 1.5px;
	font-size: 14px;
	font-weight: 700;
}

.courses-curriculum ul li .courses-meta .status {
	display: inline-block;
	background: #000000;
	color: #ffffff;
	border-radius: 3px;
	margin-left: 6px;
	padding: 2px 10px 1.5px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
}

.courses-curriculum ul li .courses-meta .status.locked {
	color: #000000;
	background-color: transparent;
	padding: 0;
	margin-left: 8px;
	border-radius: 0;
	position: relative;
	top: 1px;
	font-size: 18px;
	font-weight: normal;
}

.courses-curriculum ul li:hover {
	color: #098b99;
}

.courses-curriculum ul:nth-child(2),
.courses-curriculum ul:nth-child(4),
.courses-curriculum ul:nth-child(6),
.courses-curriculum ul:nth-child(8),
.courses-curriculum ul:nth-child(10),
.courses-curriculum ul:nth-child(12),
.courses-curriculum ul:nth-child(14),
.courses-curriculum ul:nth-child(16),
.courses-curriculum ul:nth-child(18),
.courses-curriculum ul:nth-child(20),
.courses-curriculum ul:nth-child(22),
.courses-curriculum ul:nth-child(24),
.courses-curriculum ul:nth-child(26),
.courses-curriculum ul:nth-child(28),
.courses-curriculum ul:nth-child(30),
.courses-curriculum ul:nth-child(32),
.courses-curriculum ul:nth-child(34),
.courses-curriculum ul:nth-child(36),
.courses-curriculum ul:nth-child(38),
.courses-curriculum ul:nth-child(40),
.courses-curriculum ul:nth-child(42),
.courses-curriculum ul:nth-child(44),
.courses-curriculum ul:nth-child(46),
.courses-curriculum ul:nth-child(48),
.courses-curriculum ul:nth-child(50),
.courses-curriculum ul:nth-child(52),
.courses-curriculum ul:nth-child(54),
.courses-curriculum ul:nth-child(56),
.courses-curriculum ul:nth-child(58),
.courses-curriculum ul:nth-child(60),
.courses-curriculum ul:nth-child(62),
.courses-curriculum ul:nth-child(64),
.courses-curriculum ul:nth-child(66),
.courses-curriculum ul:nth-child(68),
.courses-curriculum ul:nth-child(70),
.courses-curriculum ul:nth-child(72),
.courses-curriculum ul:nth-child(74),
.courses-curriculum ul:nth-child(76),
.courses-curriculum ul:nth-child(78),
.courses-curriculum ul:nth-child(80),
.courses-curriculum ul:nth-child(82),
.courses-curriculum ul:nth-child(84),
.courses-curriculum ul:nth-child(86),
.courses-curriculum ul:nth-child(88),
.courses-curriculum ul:nth-child(90),
.courses-curriculum ul:nth-child(92),
.courses-curriculum ul:nth-child(94),
.courses-curriculum ul:nth-child(96),
.courses-curriculum ul:nth-child(98),
.courses-curriculum ul:nth-child(100) {
	background-color: #ffffff;
}

.course-details-sidebar .course-preview {
	position: relative;
}

.course-details-sidebar .course-preview img {
	opacity: 0.7;
}

.course-details-sidebar .course-preview .videos-btn {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.course-details-sidebar .sidebar-futcher {
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	padding: 30px;
}

.course-details-sidebar .sidebar-futcher .sidebar-title {
	margin-bottom: 30px;
}

.course-details-sidebar .sidebar-futcher .sidebar-title h2 {
	font-size: 25px;
	color: #098b99;
	margin-bottom: 0;
}

.course-details-sidebar .sidebar-futcher .sidebar-title h2 del {
	color: #9d9d9d;
	font-size: 20px;
	font-weight: 400;
}

.course-details-sidebar .sidebar-futcher .sidebar-title p span {
	color: #0d0c0c;
}

.course-details-sidebar .sidebar-futcher ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.course-details-sidebar .sidebar-futcher ul li {
	margin-bottom: 15px;
	padding-bottom: 15px;
	color: #0d0c0c;
	font-weight: 600;
	border-bottom: 1px solid #f5f5f5;
}

.course-details-sidebar .sidebar-futcher ul li span {
	color: #646464;
	float: right;
}

.course-details-sidebar .sidebar-futcher ul li:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.course-details-sidebar .sidebar-futcher ul li i {
	color: #098b99;
	position: relative;
	top: 1px;
	padding-right: 8px;
}

.course-details-sidebar .sidebar-futcher .cart-wish {
	margin-top: 30px;
}

.course-details-sidebar .sidebar-futcher .cart-wish .default-btn {
	width: 100%;
	display: block;
}

.course-details-sidebar .sidebar-futcher .cart-wish .wish-btn {
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background-color: #f0bf79;
	font-size: 20px;
	color: #ffffff;
	border-radius: 5px;
	display: block;
	padding: 0 16px;
}

.course-details-sidebar .sidebar-futcher .default-btn.buy {
	width: 100%;
	display: block;
	background: transparent;
	border: 1px solid #098b99;
	color: #098b99;
	margin-top: 20px;
	margin-bottom: 20px;
}

.course-details-sidebar .sidebar-futcher .default-btn.buy:hover {
	color: #ffffff;
}

/*====================================================
OTHERS STYLE AREA
=====================================================*/
/*
Preloader Area Style*/
.preloader {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: #ffffff;
	top: 0;
	left: 0;
	z-index: 99999;
}

.preloader .lds-ripple {
	position: absolute;
	width: 80px;
	height: 80px;
	-webkit-animation: spin-six 2.5s infinite cubic-bezier(0.17, 0.72, 0.55, 1.66);
	animation: spin-six 2.5s infinite cubic-bezier(0.17, 0.72, 0.55, 1.66);
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-40px, -50px);
	transform: translate(-40px, -50px);
}

.preloader .preloader-container {
	width: 80px;
	height: 80px;
}

.preloader .petal {
	position: absolute;
	width: 30%;
	height: 50%;
	bottom: 50%;
	left: 50%;
	-webkit-transform-origin: bottom center;
	transform-origin: bottom center;
	border-radius: 50%;
	z-index: 2;
}

.preloader .petal::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	bottom: 0%;
	left: 0%;
	-webkit-transform-origin: bottom center;
	transform-origin: bottom center;
	border-radius: 50%;
	background-color: #098b99;
	z-index: 2;
	-webkit-animation: opacity-nine 0.8s infinite linear;
	animation: opacity-nine 0.8s infinite linear;
	opacity: 1;
	border-left: 1px solid #ffffff;
}

.preloader .petal:nth-of-type(6) {
	-webkit-transform: translate(-50%, 0%) rotate(225deg);
	transform: translate(-50%, 0%) rotate(225deg);
}

.preloader .petal:nth-of-type(6)::before {
	background-color: transparent;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

.preloader .petal:nth-of-type(7) {
	-webkit-transform: translate(-50%, 0%) rotate(270deg);
	transform: translate(-50%, 0%) rotate(270deg);
}

.preloader .petal:nth-of-type(7)::before {
	background-color: transparent;
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.preloader .petal:nth-of-type(8) {
	-webkit-transform: translate(-50%, 0%) rotate(315deg);
	transform: translate(-50%, 0%) rotate(315deg);
}

.preloader .petal:nth-of-type(8)::before {
	background-color: transparent;
	-webkit-animation-delay: 0.7s;
	animation-delay: 0.7s;
}

.preloader .petal:nth-of-type(1) {
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
}

.preloader .petal:nth-of-type(1)::before {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.preloader .petal:nth-of-type(2) {
	-webkit-transform: translate(-50%, 0%) rotate(45deg);
	transform: translate(-50%, 0%) rotate(45deg);
}

.preloader .petal:nth-of-type(2)::before {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.preloader .petal:nth-of-type(3) {
	-webkit-transform: translate(-50%, 0%) rotate(90deg);
	transform: translate(-50%, 0%) rotate(90deg);
}

.preloader .petal:nth-of-type(3)::before {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.preloader .petal:nth-of-type(4) {
	-webkit-transform: translate(-50%, 0%) rotate(135deg);
	transform: translate(-50%, 0%) rotate(135deg);
}

.preloader .petal:nth-of-type(4)::before {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.preloader .petal:nth-of-type(5) {
	-webkit-transform: translate(-50%, 0%) rotate(180deg);
	transform: translate(-50%, 0%) rotate(180deg);
}

.preloader .petal:nth-of-type(5)::before {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.preloader .petal-1 {
	position: absolute;
	width: 30%;
	height: 50%;
	bottom: 50%;
	left: 50%;
	-webkit-transform-origin: bottom center;
	transform-origin: bottom center;
	border-radius: 50%;
	z-index: -1;
}

.preloader .petal-1::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	bottom: 0%;
	left: 0%;
	-webkit-transform-origin: bottom center;
	transform-origin: bottom center;
	border-radius: 50%;
	background-color: #098b99;
	z-index: 2;
	-webkit-animation: opacity-nine 0.8s infinite linear;
	animation: opacity-nine 0.8s infinite linear;
	opacity: 1;
}

.preloader .petal-1:nth-of-type(9) {
	-webkit-transform: translate(-50%, 0%) rotate(225deg);
	transform: translate(-50%, 0%) rotate(225deg);
}

.preloader .petal-1:nth-of-type(9)::before {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

.preloader .petal-1:nth-of-type(10) {
	-webkit-transform: translate(-50%, 0%) rotate(270deg);
	transform: translate(-50%, 0%) rotate(270deg);
}

.preloader .petal-1:nth-of-type(10)::before {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.preloader .petal-1:nth-of-type(11) {
	-webkit-transform: translate(-50%, 0%) rotate(315deg);
	transform: translate(-50%, 0%) rotate(315deg);
}

.preloader .petal-1:nth-of-type(11)::before {
	-webkit-animation-delay: 0.7s;
	animation-delay: 0.7s;
}

.preloader .ball {
	position: absolute;
	width: 28%;
	height: 28%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 50%;
	border: 1px solid #ffffff;
	background-color: #098b99;
	z-index: 3;
}

.preloader.preloader-deactivate {
	visibility: hidden;
}

@-webkit-keyframes opacity-nine {
	0% {
		opacity: 1;
	}
	95% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.2;
	}
}

@keyframes opacity-nine {
	0% {
		opacity: 1;
	}
	95% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.2;
	}
}

/*
Go Top Style*/
.go-top {
	position: fixed;
	cursor: pointer;
	right: 3%;
	top: 87%;
	background-color: #098b99;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 42px;
	line-height: 42px;
	-webkit-transform: scale(0);
	transform: scale(0);
	visibility: hidden;
	-webkit-transition: 0.9s;
	transition: 0.9s;
}

.go-top i {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
	color: #ffffff;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	font-size: 20px;
}

.go-top i:last-child {
	opacity: 0;
	visibility: hidden;
	top: 60%;
}

.go-top::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: #098b99;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.go-top:hover {
	color: #ffffff;
	background-color: #098b99;
}

.go-top:hover::before {
	opacity: 1;
	visibility: visible;
}

.go-top:hover i:first-child {
	opacity: 0;
	top: 0;
	visibility: hidden;
}

.go-top:hover i:last-child {
	opacity: 1;
	visibility: visible;
	top: 50%;
}

.go-top:focus {
	color: #ffffff;
}

.go-top:focus::before {
	opacity: 1;
	visibility: visible;
}

.go-top:focus i:first-child {
	opacity: 0;
	top: 0;
	visibility: hidden;
}

.go-top:focus i:last-child {
	opacity: 1;
	visibility: visible;
	top: 50%;
}

.go-top.active {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	right: 3%;
	top: 87%;
	-webkit-transform: scale(1);
	transform: scale(1);
}

/*
Video wave Style*/
.video-btn {
	display: inline-block;
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	border-radius: 0;
	color: #098b99;
	position: relative;
	top: 3px;
	z-index: 1;
	background-color: rgba(98, 189, 94, 0.8);
	border-radius: 50%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.video-btn i {
	font-size: 42px;
	color: #ffffff;
	position: absolute;
	top: 0;
	left: 3px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
}

.video-btn::after,
.video-btn::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
	bottom: 0;
	left: 0;
	border-radius: 0;
	border-radius: 50%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	-webkit-animation: ripple 1.6s ease-out infinite;
	animation: ripple 1.6s ease-out infinite;
	background-color: rgba(98, 189, 94, 0.8);
}

.video-btn:hover {
	background-color: rgba(27, 27, 27, 0.8);
}

.video-btn:hover::before,
.video-btn:hover::after {
	background-color: rgba(27, 27, 27, 0.8);
}

@-webkit-keyframes ripple {
	0%,
	35% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	50% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
		opacity: 0.8;
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2);
		transform: scale(2);
	}
}

@keyframes ripple {
	0%,
	35% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	50% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
		opacity: 0.8;
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2);
		transform: scale(2);
	}
}

.video-btn-2 span {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	content: "";
	display: block;
	border-radius: 50%;
	width: 90px;
	height: 90px;
}

.video-btn-2 span::after {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 0.1px solid #098b99;
	-webkit-animation: videoTwo 8s linear infinite;
	animation: videoTwo 8s linear infinite;
}

.video-btn-2 span::before {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 0.1px solid #098b99;
	-webkit-animation: videoTwo 8s linear infinite;
	animation: videoTwo 8s linear infinite;
}

.video-btn-2 span:nth-child(1)::after {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.video-btn-2 span:nth-child(1)::before {
	-webkit-animation-delay: 5s;
	animation-delay: 5s;
}

.video-btn-2 span:nth-child(2)::after {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.video-btn-2 span:nth-child(2)::before {
	-webkit-animation-delay: 6s;
	animation-delay: 6s;
}

.video-btn-2 span:nth-child(3)::after {
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.video-btn-2 span:nth-child(3)::before {
	-webkit-animation-delay: 7s;
	animation-delay: 7s;
}

.video-btn-2 span:nth-child(4)::after {
	-webkit-animation-delay: 4s;
	animation-delay: 4s;
}

.video-btn-2 i {
	width: 90px;
	height: 90px;
	line-height: 80px;
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	background: #ffffff;
	color: #098b99;
	border: 5px solid #098b99;
	font-size: 60px;
}

.video-btn-2.video-btn-3 i {
	color: #0d0c0c;
	border-color: #0d0c0c;
}

.video-btn-2.video-btn-3 span::before {
	border: 0.1px solid #0d0c0c;
}

.video-btn-2.video-btn-3 span::after {
	border: 0.1px solid #0d0c0c;
}

@-webkit-keyframes videoTwo {
	0% {
		-webkit-transform: translate(-50%, -50%) scale(1);
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%, -50%) scale(5);
		transform: translate(-50%, -50%) scale(5);
		opacity: 0;
	}
}

@keyframes videoTwo {
	0% {
		-webkit-transform: translate(-50%, -50%) scale(1);
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%, -50%) scale(5);
		transform: translate(-50%, -50%) scale(5);
		opacity: 0;
	}
}

/*
Go Top Style*/
.go-top {
	position: fixed;
	cursor: pointer;
	right: 3%;
	top: 87%;
	background-color: #098b99;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 42px;
	line-height: 42px;
	-webkit-transform: scale(0);
	transform: scale(0);
	visibility: hidden;
	-webkit-transition: 0.9s;
	transition: 0.9s;
}

.go-top i {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
	color: #ffffff;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	font-size: 20px;
}

.go-top i:last-child {
	opacity: 0;
	visibility: hidden;
	top: 60%;
}

.go-top::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: #098b99;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.go-top:hover {
	color: #ffffff;
	background-color: #098b99;
}

.go-top:hover::before {
	opacity: 1;
	visibility: visible;
}

.go-top:hover i:first-child {
	opacity: 0;
	top: 0;
	visibility: hidden;
}

.go-top:hover i:last-child {
	opacity: 1;
	visibility: visible;
	top: 50%;
}

.go-top:focus {
	color: #ffffff;
}

.go-top:focus::before {
	opacity: 1;
	visibility: visible;
}

.go-top:focus i:first-child {
	opacity: 0;
	top: 0;
	visibility: hidden;
}

.go-top:focus i:last-child {
	opacity: 1;
	visibility: visible;
	top: 50%;
}

.go-top.active {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	right: 3%;
	top: 84%;
	-webkit-transform: scale(1);
	transform: scale(1);
}

/*
Video wave Style*/
.video-btn {
	display: inline-block;
	width: 80px;
	height: 80px;
	line-height: 80px;
	text-align: center;
	border-radius: 0;
	color: #098b99;
	position: relative;
	z-index: 1;
	background-color: #ffffff;
	border-radius: 50%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.video-btn i {
	font-size: 42px;
	color: #098b99;
	position: absolute;
	top: 0;
	left: 0px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
}

.video-btn::after,
.video-btn::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
	bottom: 0;
	left: 0;
	border-radius: 0;
	border-radius: 50%;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	-webkit-animation: ripple 1.6s ease-out infinite;
	animation: ripple 1.6s ease-out infinite;
	background-color: #ffffff;
}

.video-btn span {
	position: absolute;
	top: 0;
	right: -105px;
	width: 100px;
	font-size: 15px;
}

.video-btn:hover {
	background-color: #098b99;
}

.video-btn:hover i {
	color: #ffffff;
}

.video-btn:hover::before,
.video-btn:hover::after {
	background-color: #098b99;
}

@keyframes ripple {
	0%,
	35% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	50% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
		opacity: 0.8;
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2);
		transform: scale(2);
	}
}

/*
Page-navigation Area Style*/
.pagination-area {
	margin-top: 10px;
}

.pagination-area .page-numbers {
	width: 45px;
	height: 45px;
	line-height: 46px;
	color: #0d0c0c;
	text-align: center;
	display: inline-block;
	position: relative;
	margin-left: 3px;
	margin-right: 3px;
	font-size: 16px;
	background-color: #dfe2e2;
	border-radius: 50%;
}

.pagination-area .page-numbers:hover {
	color: #ffffff;
	background-color: #098b99;
}

.pagination-area .page-numbers i {
	font-size: 16px;
	font-weight: normal;
}

.pagination-area .page-numbers.next {
	top: 4px;
}

.pagination-area .page-numbers.current {
	color: #ffffff;
	border-color: #098b99;
	background-color: #098b99;
}

/*
Animation Area Style*/
.delay-0-2s {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.delay-0-4s {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.delay-0-6s {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.delay-0-8s {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.delay-0-1s {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.single-courses-box {
	margin-bottom: 30px;
	position: relative;
	border-radius: 5px;
	background-color: #fff;
	-webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.single-courses-box .courses-image {
	position: relative;
}

.single-courses-box .courses-content {
	border-radius: 0 0 5px 5px;
	padding: 30px;
}

.single-courses-box .courses-content h3 {
	margin-bottom: 12px;
	line-height: 1.5;
	color: #0d0c0c;
	font-size: 18px;
}

.single-courses-box .courses-content h3 a {
	display: inline-block;
	color: #0d0c0c;
}

.single-courses-box .video_box {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: rgba(0, 0, 0, 0.4);
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.single-courses-box .video_box i {
	background-color: #fff;
	width: 60px;
	height: 60px;
	line-height: 60px;
	color: #000;
	font-size: 50px;
	border-radius: 80px;
	padding-left: 5px;
	cursor: pointer;
}

.single-courses-box .video_box i:hover {
	background-color: #098b99;
	color: #fff;
}

.single-courses-box:hover .video_box {
	-webkit-transform: scale(1);
	transform: scale(1);
}

/*================================================
Checkout Area CSS
=================================================*/
.shopping-cart-list {
	-webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	margin-bottom: 30px;
	padding: 20px;
}

.shopping-cart-list .content h3 {
	font-size: 16px;
	font-weight: 700;
}

.shopping-cart-list .content .list {
	margin: 0;
	padding: 0;
}

.shopping-cart-list .content .list li {
	font-size: 14px;
	color: #fec400;
	display: inline-block;
	margin-right: 15px;
}

.shopping-cart-list .content .list li:last-child {
	margin-right: 0;
}

.shopping-cart-list .action-button {
	text-align: end;
}

.shopping-cart-list .action-button .remove {
	font-size: 14px;
}

.shopping-cart-list .action-button .wishlist {
	color: #098b99;
	font-size: 14px;
}

.shopping-cart-list hr {
	border-color: #cab3b3;
}

.shopping-cart-list .col-lg-12:last-child hr {
	border-color: #eeeeee;
	display: none;
}

.teacher-courses-box {
	margin-bottom: 30px;
	position: relative;
	border-radius: 5px;
	background-color: #ffffff;
	-webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.teacher-courses-box .courses-image {
	border-radius: 5px 5px 0 0;
	position: relative;
	z-index: 1;
}

.teacher-courses-box .courses-image .image {
	border-radius: 5px 5px 0 0;
}

.teacher-courses-box .courses-image .image img {
	border-radius: 5px 5px 0 0;
}

.teacher-courses-box .courses-image .fav {
	display: inline-block;
	position: absolute;
	right: 20px;
	top: 20px;
	color: #ff5555;
	font-size: 35px;
	line-height: 1;
	padding: 0;
	border: none;
}

.teacher-courses-box .courses-image .fav:hover {
	color: red;
}

.teacher-courses-box .courses-image .price {
	display: inline-block;
	background-color: #098b99;
	color: #ffffff;
	width: 65px;
	height: 65px;
	border-radius: 50%;
	position: absolute;
	right: 20px;
	bottom: -32.5px;
	text-align: center;
	line-height: 67px;
	font-size: 16px;
	font-weight: 800;
}

.teacher-courses-box .courses-image .discount-price {
	right: 100px;
	background: red;
}

.teacher-courses-box .courses-content {
	border-radius: 0 0 5px 5px;
	padding: 30px;
}

.teacher-courses-box .courses-content .course-author {
	margin-bottom: 15px;
}

.teacher-courses-box .courses-content .course-author img {
	width: 35px;
	height: 35px;
	margin-right: 10px;
}

.teacher-courses-box .courses-content .course-author span {
	color: #098b99;
	font-weight: 600;
	font-size: 15px;
}

.teacher-courses-box .courses-content h3 {
	margin-bottom: 12px;
	line-height: 1.3;
	font-size: 24px;
	font-weight: 800;
}

.teacher-courses-box .courses-content h3 a {
	display: inline-block;
}

.teacher-courses-box .courses-content .courses-box-footer {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	margin-left: -7px;
	margin-right: -7px;
	margin-top: 20px;
}

.teacher-courses-box .courses-content .courses-box-footer li {
	color: #fec400;
	font-size: 15px;
	position: relative;
	padding-left: 25px;
	padding-right: 7px;
}

.teacher-courses-box .courses-content .courses-box-footer li i {
	color: #098b99;
	position: absolute;
	left: 7px;
	top: 0;
}

.teacher-courses-box .courses-content .courses-box-footer li i.flaticon-people {
	font-size: 18px;
	left: 3px;
}

.teacher-courses-box.bg-color {
	background-color: #fffaf3;
	-webkit-box-shadow: unset;
	box-shadow: unset;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.teacher-courses-box.bg-color .courses-content h3 {
	margin-bottom: 10px;
}

.teacher-courses-box.bg-color:hover {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
	box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
}

.teacher-courses-box.without-boxshadow {
	background-color: #ffffff;
	-webkit-box-shadow: unset;
	box-shadow: unset;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.teacher-courses-box.without-boxshadow .courses-content h3 {
	margin-bottom: 10px;
}

.teacher-courses-box.without-boxshadow:hover,
.teacher-courses-box.without-boxshadow.active {
	-webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
	box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
}

.teacher-courses-box.style-2 .video_box {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: rgba(0, 0, 0, 0.4);
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.teacher-courses-box.style-2 .video_box i {
	background-color: #fff;
	width: 60px;
	height: 60px;
	line-height: 60px;
	color: #000;
	font-size: 50px;
	border-radius: 80px;
	padding-left: 5px;
	cursor: pointer;
}

.teacher-courses-box.style-2 .video_box i:hover {
	background-color: #098b99;
	color: #fff;
}

.teacher-courses-box.style-2:hover .video_box {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.teacher-courses-box.style-2 .courses-content {
	padding: 20px;
}

.teacher-courses-box.style-2 .courses-content h3 {
	font-size: 18px;
}

.modal {
	display: block;
	opacity: 1;
}

.modal .modal-dialog {
	/* max-width: 720px; */
	max-width: 90%;
}

.modal .modal-content {
	display: block;
	border: none;
	padding: 0;
	background-color: #ffffff;
	-webkit-box-orient: unset;
	-webkit-box-direction: unset;
	-ms-flex-direction: unset;
	flex-direction: unset;
	border-radius: 0;
}

.modal .modal-content .btn-close {
	position: absolute;
	right: -35px;
	top: -35px;
	color: #ffffff;
	opacity: 1;
	width: auto;
	cursor: pointer;
	height: auto;
	border-radius: 0;
	padding: 0;
	-webkit-box-shadow: unset;
	box-shadow: unset;
	color: #ffffff;
	background-image: unset;
	font-size: 30px;
}

.modal-backdrop {
	background: rgba(18, 18, 18, 0.7) !important;
	-webkit-backdrop-filter: blur(20px);
	backdrop-filter: blur(3px);
}

@media only screen and (max-width: 767px) {
	.modal .modal-dialog {
		max-width: 100%;
	}
	.modal .modal-content .btn-close {
		right: 0;
		top: -35px;
		font-size: 25px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.modal .modal-dialog {
		/* max-width: 700px; */
		max-width: 90%;
	}
	.modal .modal-content .btn-close {
		right: -25px;
		top: -35px;
	}
}

.get-certificate .content {
	position: absolute;
	left: 0;
	right: 0;
	top: 52%;
	text-align: center;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
	-webkit-transform: translateY(-52%);
	transform: translateY(-52%);
}

.get-certificate .content h2 {
	font-weight: 800;
}

.get-certificate .certificate-img {
	position: relative;
}

.get-certificate .caption {
	text-align: right;
	margin-top: -50px;
	position: relative;
	padding-right: 10px;
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	z-index: 2;
}

.get-certificate .caption .download-btn {
	width: 40px;
	height: 40px;
	line-height: 42px;
	background-color: #fff;
	border: 0;
	padding: 0;
	font-size: 40px;
	border-radius: 5px;
	color: #f44336;
}

.get-certificate .caption .download-btn:nth-child(1) {
	color: #3100f3;
}

.get-certificate .col-lg-8:hover .caption {
	opacity: 1;
}

.live-class-icon {
	display: inline-block;
	width: 15px;
	height: 15px;
	z-index: 1;
	-webkit-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
	background-color: red;
	border-radius: 50%;
	position: relative;
	top: 4px;
}

.live-class-icon::after,
.live-class-icon::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
	bottom: 0;
	left: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	border-radius: 50%;
	border: 1px solid red;
}

.live-class-icon::before {
	-webkit-animation: ripple 2s linear infinite;
	animation: ripple 2s linear infinite;
}

.live-class-icon::after {
	-webkit-animation: ripple 2s linear 1s infinite;
	animation: ripple 2s linear 1s infinite;
}

.coupon {
	margin-top: 20px;
}

.coupon h4 {
	font-size: 17px;
	font-weight: bold;
	cursor: pointer;
	text-decoration: underline;
	text-align: center;
}

.coupon form {
	position: relative;
	width: 100%;
	border-radius: 5px;
}

.coupon form .input-search {
	display: block;
	width: 100%;
	height: 48px;
	border: none;
	border-radius: 5px;
	background-color: #f5f5f5;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
	color: #000000;
	font-size: 16px;
	font-weight: 400;
	padding-left: 15px;
	padding-top: 1px;
	padding-bottom: 0;
	padding-right: 0;
}

.coupon form .input-search::-webkit-input-placeholder {
	color: #848484;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coupon form .input-search:-ms-input-placeholder {
	color: #848484;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coupon form .input-search::-ms-input-placeholder {
	color: #848484;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coupon form .input-search::placeholder {
	color: #848484;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

.coupon form .input-search:focus {
	border-color: #098b99;
}

.coupon form .input-search:focus::-webkit-input-placeholder {
	color: transparent;
}

.coupon form .input-search:focus:-ms-input-placeholder {
	color: transparent;
}

.coupon form .input-search:focus::-ms-input-placeholder {
	color: transparent;
}

.coupon form .input-search:focus::placeholder {
	color: transparent;
}

.coupon form button {
	position: absolute;
	right: 0;
	top: 0;
	height: 48px;
	background-color: #1cab94;
	border: none;
	color: #fff;
	border-radius: 5px;
	padding: 0 15px;
}
